import React, { useCallback, useEffect, useState } from 'react';
import GQLClient from '../GQLClient';
import { createHierarchy, createHierarchyItem } from '../graphql/mutations';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@mui/material';

import { hierarchyItemsByParentId, listHierarchies } from '../graphql/queries';
import { HierarchyLineItem } from './HierarchyLineItem';
import { CreateHierarchyInput, CreateHierarchyItemInput, UpdateHierarchyInput, UpdateHierarchyItemInput } from '../API';
import { updateHierarchyItem } from '../graphql/mutations';
import { Add } from '@mui/icons-material';
import LoadingIndicator from '../common/LoadingIndicator';

export default function HierarchyView() {
  const [expanded, setExpanded] = useState<string | false>(false);
  const [selected, setSelected] = useState<UpdateHierarchyItemInput | undefined>();
  const [hierarchy, setHierarchy] = useState<CreateHierarchyInput | UpdateHierarchyInput>()
  const [hierarchyItems, setHierarchyItems] = useState<any[]>([])

  const [addDialogOpen, setAddDialogOpen] = useState<boolean>(false)
  const [name, setName] = useState<string>('')
  const [code, setCode] = useState<string>('')
  const [hiddenChange, setHiddenChange] = useState<boolean>(false)

  const fetchHierarchy = useCallback(async () => {
    const hierarchyData = await GQLClient.graphql({ query: listHierarchies }) as any
    const hierarchies = hierarchyData.data.listHierarchies.items

    if (hierarchies.length) {
      setHierarchy(hierarchies[0])
      await fetchHierarchyItems(hierarchies[0].id);
    } else {
      setHierarchy({
        name: ''
      })
    }
  }, [])

  useEffect(() => {
    fetchHierarchy()
  }, [fetchHierarchy])

  async function fetchHierarchyItems(hierarchyId: string) {
    const hierarchyItemsData = await GQLClient.graphql({
      query: hierarchyItemsByParentId, variables: {
        parentId: hierarchyId
      }
    }) as any

    const hierarchyItems = hierarchyItemsData.data.hierarchyItemsByParentId.items
      .filter((i: any) => !i._deleted)
      .sort((a: any, b: any) => a.code > b.code ? 1 : -1)

    setHierarchyItems(hierarchyItems)
  }

  function setHierarchyValue(key: string, value: string) {
    setHierarchy({
      ...hierarchy,
      [key]: value
    } as UpdateHierarchyInput)
  }

  async function addHierarchy() {
    try {
      if (!hierarchy || !hierarchy.name) return
      const hierarchyData = await GQLClient.graphql({ query: createHierarchy, variables: { input: hierarchy } }) as any
      setHierarchy(hierarchyData.data.createHierarchy)
    } catch (err) {
      console.log('error creating hierarchy:', err)
    }
  }

  async function addHierarchyItem() {
    try {
      if (!hierarchy || !name) return
      const hierarchyItemData = await GQLClient.graphql({
        query: createHierarchyItem, variables: {
          input: {
            name: name,
            code: code,
            hierarchyId: hierarchy.id,
            parentId: hierarchy.id,
          } as CreateHierarchyItemInput
        }
      }) as any
      const hierarchyItem = hierarchyItemData.data.createHierarchyItem

      setHierarchyItems([
        ...hierarchyItems,
        hierarchyItem
      ])
      setName('')
      setCode('')
    } catch (err) {
      console.log('error creating hierarchy item:', err)
    }
  }

  const handleChange = (panel: string, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
    setSelected(isExpanded ? hierarchyItems.find(i => i.id === panel) : undefined);
  };

  const handleHide = async (item: any, checked: boolean) => {
    const itemToUpdate = { id: item.id, hidden: checked, _version: item._version }
    await GQLClient.graphql({ query: updateHierarchyItem, variables: { input: itemToUpdate } })
    let updatedItems = [...hierarchyItems]
    const i = updatedItems.findIndex(i => i.id === item.id)
    setHiddenChange(!hiddenChange)
    if (i > -1) {
      updatedItems[i].hidden = checked
      setHierarchyItems(updatedItems)
    }
  }

  if (!hierarchy) {
    return <LoadingIndicator />
  } else {
    if (!hierarchy.id) {
      return (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5" style={{ fontWeight: 'bold' }}>
              Add Hierarchy
            </Typography>
          </Grid>
          <Grid container spacing={3} item xs={6}>
            <Grid item xs={12}>
              <TextField
                label="Hierarchy Name"
                variant="outlined"
                size="small"
                fullWidth
                onChange={(event: any) => setHierarchyValue('name', event.target.value)}
                value={hierarchy.name}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={addHierarchy}
              style={{ fontWeight: 'bold', textTransform: 'uppercase' }}
            >
              Add
            </Button>
          </Grid>
        </Grid>
      );
    } else {
      return (
        <div>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                {hierarchy.name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {!selected && hierarchyItems.map(item =>
                <HierarchyLineItem
                  key={item.id}
                  expanded={expanded}
                  onChange={handleChange}
                  onHide={handleHide}
                  hiddenChange={hiddenChange}
                  item={item}
                  hierarchyItems={hierarchyItems}
                />
              )}
              {selected &&
                <HierarchyLineItem
                  key={selected.id}
                  expanded={expanded}
                  onChange={handleChange}
                  onHide={handleHide}
                  hiddenChange={hiddenChange}
                  item={selected}
                  hierarchyItems={hierarchyItems}
                />
              }
            </Grid>
          </Grid>
          {false && !expanded &&
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<Add />}
                  onClick={() => setAddDialogOpen(true)}
                >
                  Add Hierarchy Item
                </Button>
                <Dialog fullWidth={true} maxWidth="sm" open={addDialogOpen} onClose={() => setAddDialogOpen(false)}>
                  <DialogTitle>Add Hierarchy Item</DialogTitle>
                  <DialogContent>
                    <TextField
                      style={{ marginBottom: 10 }}
                      label="Hierarchy Item Name"
                      variant="outlined"
                      size="small"
                      fullWidth
                      onChange={(event: any) => setName(event.target.value)}
                      value={name}
                    />
                    <TextField
                      label="Hierarchy Item Code"
                      variant="outlined"
                      size="small"
                      fullWidth
                      onChange={(event: any) => setCode(event.target.value)}
                      value={code}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setAddDialogOpen(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={addHierarchyItem}>
                      Add
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
            </Grid>
          }
        </div>
      );
    }
  }
}
