import React from 'react';
import { Grid, Paper } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import ProjectsGrid from '../project/ProjectsGrid';
import { UpdateProjectInput } from '../API';

export const ClientProjects = () => {
  const { id } = useParams() as any;
  const navigate = useNavigate();

  function onSelect(item: UpdateProjectInput) {
    navigate(`/projects/${item.id}`)
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper>
          <ProjectsGrid
            id="clientProjects"
            filter={{ clientId: { eq: id } }}
            onSelect={onSelect}
            columnDefs={[
              {
                field: "name",
                headerName: "Project",
                sortable: true,
                filter: "agTextColumnFilter",
                filterParams: { suppressAndOrCondition: true }
              },
              {
                field: "address.city",
                headerName: "City",
                sortable: false
              },
              {
                field: "address.state",
                headerName: "State",
                sortable: false
              },
              {
                headerName: "EFT",
                field: "eft",
                cellRenderer: (params: any) => {
                  if (params.data && params.data.eft) {
                    return params.data.eft.firstName + ' ' + params.data.eft.lastName
                  } else {
                    return ''
                  }
                }
              },
              {
                field: "status",
                sortable: true,
                filter: "agTextColumnFilter",
                filterParams: { suppressAndOrCondition: true }
              }
            ]}>
          </ProjectsGrid>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default ClientProjects
