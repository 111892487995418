import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Avatar, Box, Button, Dialog, DialogActions, DialogContent, FormControl, IconButton, Input, InputAdornment, InputLabel, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import GQLClient from '../GQLClient';
import { UpdateUserInput } from '../API';
import { UserType } from '../models';
import { Search } from '@mui/icons-material';

export const searchUsers = /* GraphQL */ `
  query SearchUsers(
    $filter: SearchableUserFilterInput
    $sort: [SearchableUserSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        type
        firstName
        lastName
        companyName
        jobTitle
        phone
        email
        _version
        _deleted
        createdAt
        updatedAt
        owner
      }
      nextToken
      total
    }
  }
`;

export const UserPicker: React.FC<{ open: boolean, type?: UserType, onSelect: Function, onClose: Function }> = ({ open, type, onSelect, onClose }) => {
  const [users, setUsers] = useState<UpdateUserInput[]>([])
  const [query, setQuery] = useState<string>()

  const queryUsers = useCallback(async () => {
    try {

      let searchParams: any

      if (query || type) {
        searchParams = {
          filter: {}
        }

        if (type) {
          searchParams.filter.type = { eq: type }
        }

        if (query) {
          searchParams.filter.or = [
            { firstName: { wildcard: `*${query.toLowerCase()}*` } },
            { lastName: { wildcard: `*${query.toLowerCase()}*` } },
            { companyName: { wildcard: `*${query.toLowerCase()}*` } }
          ]
        }
      }

      const usersData = await GQLClient.graphql({ query: searchUsers, variables: searchParams }) as any
      const users = usersData.data.searchUsers.items
      setUsers(users)
    } catch (err) { console.log('error fetching users') }
  }, [type, query])


  useEffect(() => {
    if (open) {
      queryUsers()
    }
  }, [open, queryUsers])


  const close = () => {
    onClose && onClose()
  }

  const select = (item: UpdateUserInput) => {
    onSelect && onSelect(item)
  }

  return (
    <Dialog fullWidth={true} maxWidth="sm" open={open} onClose={close}>
      <DialogContent>
        <Box display="flex" flexDirection="column">
          <FormControl>
            <InputLabel htmlFor="user-search">Search Users</InputLabel>
            <Input
              id="user-search"
              type="text"
              value={query}
              fullWidth
              onChange={(event: ChangeEvent<HTMLInputElement>) => setQuery(event.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={queryUsers}
                  >
                    <Search />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <List
            component="div"
            dense={true}
          >
            {users.map(item => (
              <ListItem key={item.id} button onClick={() => select(item)} alignItems="flex-start" style={{ alignItems: 'center' }}>
                <ListItemAvatar>
                  <Avatar>
                    {`${item.firstName ? item.firstName[0] : ''}${item.lastName ? item.lastName[0] : ''}`}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  style={{ flex: 1 }}
                  primary={`${item.firstName} ${item.lastName}`}
                  secondary={item.companyName}
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} variant="outlined" color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}