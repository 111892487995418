import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Box, Grid, TextField } from '@mui/material';

import GQLClient from '../GQLClient';
import { commentsByInspectionId } from '../graphql/queries';
import { useParams } from 'react-router-dom';
import { CommentListItem } from './CommentListItem';
import { createComment, createInspectionComment } from '../graphql/mutations';
import { CreateCommentInput } from '../API';
import { useCurrentUser } from '../hooks/AuthHooks';
import LoadingIndicator from '../common/LoadingIndicator';
import { LoadingButton } from '../common/LoadingButton';
import { InspectionContext } from './InspectionContext';

export const InspectionComments: React.FC<{}> = (props) => {
  const { id } = useParams() as any;
  const { inspection } = useContext(InspectionContext);

  const user = useCurrentUser()
  const [commentText, setCommentText] = useState<string>()
  const [comments, setComments] = useState<any[]>()
  const fetchComments = useCallback(async () => {
    const inspectionCommentsData = await GQLClient.graphql({ query: commentsByInspectionId, variables: { inspectionId: id } }) as any
    const inspectionComments = inspectionCommentsData.data.commentsByInspectionId.items as any[]
    const comments = inspectionComments.map(c => c.comment)
      .filter((c: any) => !c._deleted)
      .sort((a: any, b: any) => a.date < b.date ? 1 : -1)
    setComments(comments)
  }, [id])

  useEffect(() => {
    fetchComments()
  }, [fetchComments])

  const addComment = async () => {
    try {
      if (!commentText || !user) return
      let comment: CreateCommentInput = {
        groupId: inspection!.groupId || inspection!.projectId,
        userId: user,
        date: new Date().toISOString(),
        text: commentText,
        projectId: inspection!.projectId
      }

      const commentData = await GQLClient.graphql({ query: createComment, variables: { input: comment } }) as any
      comment = commentData.data.createComment

      await GQLClient.graphql({
        query: createInspectionComment, variables: {
          input: {
            groupId: inspection!.groupId || inspection!.projectId!,
            inspectionId: id,
            commentId: comment.id,
            projectId: inspection!.projectId
          }
        }
      }) as any

      setComments([
        comment,
        ...comments!
      ])
    } catch (err) {
      console.log('error creating comment:', err)
    }
  }

  if (!comments) return <LoadingIndicator />

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          name="comment"
          label="Comment"
          fullWidth
          variant="outlined"
          multiline
          rows={4}
          maxRows={4}
          value={commentText}
          onChange={(event: any) => setCommentText(event.target.value)}
        />
      </Grid>
      <Grid item xs={12} style={{ alignContent: 'center' }}>
        <Box display="flex" justifyContent="center">
          <LoadingButton onClick={addComment} variant="contained" color="primary" disabled={!commentText}>
            Add Comment
          </LoadingButton>
        </Box>
      </Grid>
      {comments.map(c =>
        <Grid item xs={12} key={c.id}>
          <CommentListItem item={c} />
        </Grid>
      )}
    </Grid>
  );
}