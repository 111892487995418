import { CircularProgress } from '@mui/material'
import React from 'react'

export default function LoadingIndicator() {
  return (
    <CircularProgress size={120} style={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -60,
      marginLeft: -60
    }}
    />
  )
}
