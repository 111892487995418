import React, { useCallback, useEffect, useState } from 'react';
import GQLClient from '../GQLClient';
import { get } from 'aws-amplify/api';
import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, Tab, Tabs, Typography } from '@mui/material';

import { Route, useParams, useNavigate, Routes, useLocation, useResolvedPath } from 'react-router-dom';
import { findingsByInspectionId, getInspection, getProject, observationsByInspectionIdAndStatus, searchActivityStreams } from '../graphql/queries';
import { CreateActivityStreamInput, UpdateInspectionInput, UpdatePhaseInput, UpdateProjectInput } from '../API';
import { InspectionOverview } from './InspectionOverview';
import { InspectionComments } from './InspectionComments';
import { InspectionDetails } from './InspectionDetails';
import { PriorItems } from './PriorItems';
import { FindingStatus, InspectionStatus, ObservationStatus } from '../models';
import LoadingIndicator from '../common/LoadingIndicator';
import { useCurrentUser } from '../hooks/AuthHooks';
import { updateFinding, updateInspection } from '../graphql/mutations';
import { InspectionObservations } from './InspectionObservations';
import { ObservationView } from './ObservationView';
import { LoadingButton } from '../common/LoadingButton';
import { InspectionContext, InspectionContextType } from './InspectionContext';
import InspectionNumberEditor from './InspectionNumberEditor';
import { CoverLetter } from './CoverLetter';

export const InspectionView = () => {
  const params = useParams() as any;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const overview = useResolvedPath('')
  const details = useResolvedPath('details')
  const observations = useResolvedPath('observations')
  const comments = useResolvedPath('comments')
  const priorItems = useResolvedPath('priorItems')
  const coverLetter = useResolvedPath('coverLetter')

  const [tabs, setTabs] = useState<any[]>([])
  useEffect(() => {
    setTabs([
      { label: "Overview", url: overview.pathname },
      { label: "Details", url: details.pathname },
      { label: "Observations", url: observations.pathname },
      { label: "Comments", url: comments.pathname },
      { label: "Prior Items", url: priorItems.pathname },
      { label: "Cover Letter", url: coverLetter.pathname }
    ])
  }, [overview, details, observations, comments, priorItems, coverLetter])

  const [activityStream, setActivityStream] = useState<CreateActivityStreamInput[]>([])
  const [selectedTab, setSelectedTab] = useState<string | false>(false)
  useEffect(() => {
    const selected = tabs.find(t => t.url === pathname)
    setSelectedTab(selected?.url || false)
  }, [tabs, pathname])

  const user = useCurrentUser()

  const handleCallToRouter = (event: React.ChangeEvent<{}>, value: string) => {
    navigate(value);
  }

  const [inspection, setInspection] = useState<UpdateInspectionInput>()
  const fetchInspection = useCallback(async () => {
    const inspectionData = await GQLClient.graphql({ query: getInspection, variables: { id: params.id } }) as any
    const inspection = inspectionData.data.getInspection
    setInspection(inspection)

    const activityData = await GQLClient.graphql({ query: searchActivityStreams, variables: { filter: { modelType: { eq: "inspection" }, instanceId: { eq: params.id } } } }) as any
    const activityDataItems = activityData.data.searchActivityStreams.items
      .sort((a: any, b: any) => (b.date > a.date) ? 1 : ((a.date > b.date) ? -1 : 0))
    setActivityStream(activityDataItems)
  }, [params])

  useEffect(() => {
    fetchInspection()
  }, [fetchInspection])

  const [inspectionContext, setInspectionContext] = useState<InspectionContextType>()
  useEffect(() => {
    const update = async (updates: any) => {
      let input: UpdateInspectionInput = {
        id: inspection!.id,
        _version: inspection!._version,
        ...updates
      }

      const inspectionData = await GQLClient.graphql({ query: updateInspection, variables: { input: input } }) as any
      const updatedInspection = inspectionData.data.updateInspection
      setInspection(updatedInspection)

      if (updates.inspectionDate) {
        const findingsData = await GQLClient.graphql({ query: findingsByInspectionId, variables: { inspectionId: inspection!.id } }) as any
        const findings = findingsData.data.findingsByInspectionId.items as any[]

        await Promise.all(
          findings
            .map(async f => GQLClient.graphql({
              query: updateFinding, variables: {
                input: {
                  id: f.id,
                  _version: f._version,
                  date: inspection!.inspectionDate
                }
              }
            })
            )
        )
      }
    }

    if (inspection) {
      setInspectionContext({
        inspection: inspection,
        editable: (
          inspection.status === InspectionStatus.REVIEWING
          || inspection.status === InspectionStatus.REVISING
          || inspection.status === InspectionStatus.ACTIVEW
        ),
        update: update
      })
    } else {
      setInspectionContext({
        inspection: undefined,
        editable: false,
        update: update
      })
    }
  }, [inspection])

  const [project, setProject] = useState<UpdateProjectInput>()
  const fetchProject = useCallback(async () => {
    if (inspection && inspection.projectId) {
      const projectData = await GQLClient.graphql({ query: getProject, variables: { id: inspection.projectId } }) as any
      const project = projectData.data.getProject
      setProject(project)
      setSelectedReportTemplate(project.reportTemplateId)
    }
  }, [inspection])

  useEffect(() => {
    fetchProject()
  }, [fetchProject])

  const [phase, setPhase] = useState<UpdatePhaseInput>()
  useEffect(() => {
    let rawInspection = inspection as any;
    if (rawInspection && rawInspection.phase) {
      setPhase(rawInspection.phase)
    } else {
      setPhase(undefined)
    }
  }, [inspection])

  const changeStatus = async (status: InspectionStatus, additionalUpdates?: any) => {
    if (!inspection) return

    let updates: any = {
      ...additionalUpdates,
      status: status
    }

    if (status === InspectionStatus.REVIEWING) {
      updates = {
        ...updates,
        reviewedDate: new Date().toISOString(),
        inspectionReviewerId: user
      }
    } else if (status === InspectionStatus.SENT) {
      updates = {
        ...updates,
        deliveredDate: new Date().toISOString()
      }
    }

    inspectionContext!.update(updates)
  }

  const [promptStartReview, setPromptStartReview] = useState<boolean>(false)
  const startReview = async (code: string, number?: string) => {
    console.log(JSON.stringify({
      code: code,
      number: number
    }))

    await changeStatus(InspectionStatus.REVIEWING, {
      code: code,
      number: number
    })
    setPromptStartReview(false)
  }

  const [confirmDeclineOpen, setConfirmDeclineOpen] = useState<boolean>(false)
  const markDeclined = async () => {
    setConfirmDeclineOpen(false)
    await changeStatus(InspectionStatus.DECLINED)
  }

  const [alertOpen, setAlertOpen] = useState<boolean>(false)
  const [confirmApproveOpen, setConfirmApproveOpen] = useState<boolean>(false)
  const markApproved = async () => {
    if (!inspection) return

    setConfirmApproveOpen(false)

    const declinedObservationData = await GQLClient.graphql({
      query: observationsByInspectionIdAndStatus, variables: {
        inspectionId: inspection.id,
        status: { eq: ObservationStatus.DECLINED },
        limit: 1
      }
    }) as any
    const declinedObservations = declinedObservationData.data.observationsByInspectionIdAndStatus.items.filter((o: any) => !o._deleted)
    if (declinedObservations.length > 0) {
      setAlertOpen(true)
    } else {
      const findingsData = await GQLClient.graphql({ query: findingsByInspectionId, variables: { inspectionId: inspection.id } }) as any
      const findings = findingsData.data.findingsByInspectionId.items as any[]

      await Promise.all(
        findings
          .filter(f => f.status === FindingStatus.NEW)
          .map(async f => GQLClient.graphql({
            query: updateFinding, variables: {
              input: {
                id: f.id,
                _version: f._version,
                status: FindingStatus.OPEN
              }
            }
          })
          )
      )

      await changeStatus(InspectionStatus.APPROVED)
    }
  }

  const markSent = async () => {
    await changeStatus(InspectionStatus.SENT, {
      scope: JSON.stringify([])
    })
  }

  const markRevising = async () => {
    await changeStatus(InspectionStatus.REVISING)
  }

  const [selectedReportTemplate, setSelectedReportTemplate] = useState<any>()
  const [reportTemplates, setReportTemplates] = useState<any>(null)
  useEffect(() => {
    const fetchTemplates = async () => {

      const getTemplates = get({
        apiName: "jsreport",
        path: "/templates"
      })


      const response = await getTemplates.response;
      const templates = await response.body.json();

      setReportTemplates(templates)
    }

    fetchTemplates()
  }, [])

  const previewReport = async () => {
    const getResponse = get({ apiName: "jsreport", path: `/templates/${selectedReportTemplate}/inspection/${params.id}` })
    const response = await getResponse.response;
    const data = await response.body.text();
    const url = JSON.parse(data).url;

    window.open(url, "_blank")
  }

  if (!inspection || !inspectionContext || !project) {
    return <LoadingIndicator />
  }

  return (
    <InspectionContext.Provider value={inspectionContext}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box display="flex" flexDirection="row">
            <Typography variant="h5" style={{ fontWeight: 'bold' }}>
              {project.name}
            </Typography>
            <Chip style={{ marginLeft: 20 }} label={inspection.status} />
          </Box>
        </Grid>
        {phase &&
          <Grid item xs={12}>
            <Typography variant="h6" style={{ fontWeight: 'bold' }}>
              {phase.name}
            </Typography>
          </Grid>
        }
        <Grid item xs={12}>
          <FormControl style={{ width: 500, marginRight: 20 }} variant="outlined" size="small">
            <InputLabel id="report-template-label">{selectedReportTemplate ? 'Report Template' : 'Select Report Template'}</InputLabel>
            <Select
              name="reportTemplateId"
              labelId="report-template-label"
              label="Report Template"
              value={selectedReportTemplate || ''}
              onChange={(event) => setSelectedReportTemplate(event.target.value)}
            >
              {reportTemplates?.map((t: any) => <MenuItem key={t.shortid} value={t.shortid}>{t.name}</MenuItem>)}
            </Select>
          </FormControl>
          <LoadingButton
            style={{ marginRight: 20 }}
            variant="outlined"
            color="primary"
            disabled={!selectedReportTemplate}
            onClick={() => previewReport()}
          >
            Generate Report
          </LoadingButton>

          {(inspection.status === InspectionStatus.SUBMITTED || inspection.status === InspectionStatus.SUBMITTING) &&
            <LoadingButton
              style={{ marginRight: 20 }}
              variant="contained"
              color="primary"
              onClick={() => setPromptStartReview(true)}
            >
              Start Review
            </LoadingButton>
          }

          {(inspection.status === InspectionStatus.REVIEWING || inspection.status === InspectionStatus.REVISING) &&
            <>
              <LoadingButton
                style={{ marginRight: 20, backgroundColor: '#F50057' }}
                variant="contained"
                color="secondary"
                onClick={() => setConfirmDeclineOpen(true)}
              >
                Decline
              </LoadingButton>
              <LoadingButton
                style={{ marginRight: 20 }}
                variant="contained"
                color="primary"
                onClick={() => setConfirmApproveOpen(true)}
              >
                Approve
              </LoadingButton>
            </>
          }
          {(inspection.status === InspectionStatus.APPROVED || inspection.status === InspectionStatus.SENT) &&
            <>
              <LoadingButton
                style={{ marginRight: 20 }}
                variant="outlined"
                color="primary"
                onClick={() => markRevising()}
              >
                Revise
              </LoadingButton>
            </>
          }
          {inspection.status === InspectionStatus.APPROVED &&
            <>
              <LoadingButton
                style={{ marginRight: 20 }}
                variant="contained"
                color="primary"
                onClick={() => markSent()}
              >
                Mark as Sent
              </LoadingButton>
            </>
          }
          {inspection.status === InspectionStatus.ACTIVEW &&
            <>
              <LoadingButton
                style={{ marginRight: 20 }}
                variant="contained"
                color="primary"
                onClick={() => changeStatus(InspectionStatus.SUBMITTED)}
              >
                Submit
              </LoadingButton>
              <LoadingButton
                style={{ marginRight: 20 }}
                variant="contained"
                color="primary"
                onClick={() => changeStatus(InspectionStatus.ACTIVE)}
              >
                Make Active on Mobile
              </LoadingButton>
            </>
          }
        </Grid>
        <Grid item xs={12}>
          <Tabs
            value={selectedTab}
            onChange={handleCallToRouter}
          >
            {tabs.map(t => <Tab key={t.label} label={t.label} value={t.url} />)}
          </Tabs>
        </Grid>
        <Grid item xs={12}>
          <Routes>
            <Route path="/" element={<InspectionOverview reviewer={inspectionContext.inspection?.inspectionReviewerId} activityStream={activityStream} />} />
            <Route path="/details" element={<InspectionDetails />} />
            <Route path="/observations" element={<InspectionObservations />} />
            <Route path="/observations/:oId" element={<ObservationView />} />
            <Route path="/comments" element={<InspectionComments />} />
            <Route path="/priorItems" element={<PriorItems />} />
            <Route path="/coverLetter" element={<CoverLetter />} />
          </Routes>
          {/*<Outlet />*/}
        </Grid>
      </Grid>
      <Dialog
        open={alertOpen}
        onClose={() => setAlertOpen(false)}
        fullWidth
      >
        <DialogTitle>{"Error"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Inspection can't be approved with declined observations
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAlertOpen(false)} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={confirmApproveOpen}
        onClose={() => setConfirmApproveOpen(false)}
        fullWidth
      >
        <DialogTitle>{"Approve"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Approve this inspection?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmApproveOpen(false)} color="primary" autoFocus>
            Cancel
          </Button>
          <Button onClick={() => markApproved()} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={confirmDeclineOpen}
        onClose={() => setConfirmDeclineOpen(false)}
        fullWidth
      >
        <DialogTitle>{"Decline"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Decline this inspection?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDeclineOpen(false)} color="primary" autoFocus>
            Cancel
          </Button>
          <Button onClick={() => markDeclined()} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <InspectionNumberEditor
        open={promptStartReview}
        code={inspection.code!}
        number={inspection.number}
        projectId={inspection.projectId!}
        phaseId={inspection.phaseId}
        onSave={(code, number) => startReview(code, number)}
        onCancel={() => setPromptStartReview(false)}
      />
    </InspectionContext.Provider>
  );
}

export default InspectionView
