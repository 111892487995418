import { Box, Button, Grid } from "@mui/material";
import React, { useState, useContext } from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { UpdateInspectionInput } from "../API";
import { InspectionContext } from './InspectionContext';


export const CoverLetter = (props: any) => {
  const { inspection, editable, update } = useContext(InspectionContext);


  const [text, setText] = useState<string>(inspection!.coverLetter ? inspection!.coverLetter : '')
  const [textChanged, setTextChanged] = useState<boolean>(false)
  const handleChange = (change: any, contens: any, source: any) => {
    if (source === 'api') return
    // Get rid of residual html after all text is deleted.
    setText(change === "<p><br></p>" ? null : change)
    setTextChanged(true)
    return
  }

  const saveCoverLetter = async () => {
    const updateInput: UpdateInspectionInput = {
      id: inspection!.id,
      _version: inspection!._version,
      coverLetter: text
    }
    update({ ...updateInput })
    setTextChanged(false)
  }

  return (
    <Grid container>
      <Grid item md={8}>
        <ReactQuill value={text} onChange={(change, contents, source) => handleChange(change, contents, source)} placeholder="Start typing...." />
      </Grid>
      <Grid item xs={12}>
        <Box mt={5}>
          <Button disabled={!editable || !textChanged} onClick={() => saveCoverLetter()} color="primary" variant="contained" >Save</Button>
        </Box>
      </Grid>
    </Grid>

  )

}