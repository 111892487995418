import React, { useEffect, useState } from 'react';
import GQLClient from '../GQLClient';
import { createContact } from '../graphql/mutations';
import { Button, Grid, Typography } from '@mui/material';

import { useNavigate } from 'react-router-dom';
import { ContactEditor } from './ContactEditor';
import { CreateContactInput, UpdateContactInput } from '../API';
import { ContactType } from '../models';

const initialState = {
  firstName: '',
  lastName: '',
  companyName: '',
  jobTitle: '',
  email: '',
  phone: '',
} as CreateContactInput

export const NewContact = () => {

  const navigate = useNavigate();
  const [contact, setContact] = useState<CreateContactInput | UpdateContactInput>(initialState)

  useEffect(() => {
  }, [])

  async function addContact() {
    try {
      if (!contact.firstName) return
      const contactData = await GQLClient.graphql({
        query: createContact, variables: {
          input: {
            ...contact,
            type: ContactType.CONTACT
          }
        }
      }) as any
      navigate(`/contacts/${contactData.data.createContact.id}`)
    } catch (err) {
      console.log('error creating contact:', err)
    }
  }

  function valid() {
    return contact.firstName
      && contact.lastName
      && contact.companyName
      //&& contact.email
      && (!contact.phone || contact.phone.replace(/\D/g, "").length === 10);
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5" style={{ fontWeight: 'bold' }}>
          Add Contact
        </Typography>
      </Grid>
      <Grid container spacing={3} item xs={6}>
        <ContactEditor contact={contact} editing={true} onChange={(contact: CreateContactInput | UpdateContactInput) => { setContact(contact) }} />
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          onClick={addContact}
          disabled={!valid()}
          style={{ fontWeight: 'bold', textTransform: 'uppercase' }}
        >
          Add
        </Button>
      </Grid>
    </Grid>
  );
}
