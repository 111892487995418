import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useImmer } from 'use-immer';
import GQLClient from '../GQLClient';
import { Button, Card, CardContent, CardHeader, FormControl, Grid, IconButton, InputLabel, List, ListItem, ListSubheader, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';

import { useParams } from 'react-router-dom';
import { Add, Edit } from '@mui/icons-material';
import { AddressEditor } from '../common/AddressEditor';
import { createProjectContact, deleteProjectContact, updateProject } from '../graphql/mutations';
import { ContactList, ProjectContact } from '../contact/ContactList';
import { ContactPicker } from '../contact/ContactPicker';
import { getProject } from '../graphql/queries';
import { AddressInput, UpdateContactInput, UpdateProjectContactInput, UpdateProjectInput, UpdateUserInput } from '../API';
import { UserPicker } from '../team/UserPicker';
import { ProjectUser } from './ProjectUser';
import { ExternalLinks } from './ExternalLinks';
import LoadingIndicator from '../common/LoadingIndicator';
import { ProjectStatus } from '../models';
import { useActiveMilestoneTemplates } from '../hooks/DataHooks';

export const projectContactsByProjectId = /* GraphQL */ `
  query ProjectContactsByProjectId(
    $projectId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProjectContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    projectContactsByProjectId(
      projectId: $projectId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        projectId
        contactId
        contact {
          id
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          _version
          _deleted
          createdAt
          updatedAt
          owner
        }
        label
        _version
        _deleted
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;

export default function ProjectDetails() {
  const { id } = useParams() as any

  const [project, setProject] = useState<UpdateProjectInput>()
  const [primaryContact, setPrimaryContact] = useState<UpdateContactInput>()
  const [eft, setEft] = useState<UpdateUserInput>()
  const [reviewer, setReviewer] = useState<UpdateUserInput>()
  const [admin, setAdmin] = useState<UpdateUserInput>()
  const [clientId, setClientId] = useState<String>()
  const [projectContacts, setProjectContacts] = useImmer<ProjectContact[]>([])
  const [editingDetails, setEditingDetails] = useState(false);
  const [editingAddress, setEditingAddress] = useState(false);
  const [addingContact, setAddingContact] = useState(false);
  const [eftPickerOpen, setEftPickerOpen] = useState(false);
  const [reviewerPickerOpen, setReviewerPickerOpen] = useState(false);
  const [adminPickerOpen, setAdminPickerOpen] = useState(false);

  const fetchProject = useCallback(async () => {
    const projectData = await GQLClient.graphql({ query: getProject, variables: { id: id } }) as any
    const project = projectData.data.getProject
    setProject(project)
  }, [id])

  useEffect(() => {
    const rawProject = project as any
    setEft(rawProject?.eft)
    setReviewer(rawProject?.reviewer)
    setAdmin(rawProject?.admin)
    setPrimaryContact(rawProject?.primaryContact)
    setClientId(rawProject?.client.id)
  }, [project])

  const fetchProjectContacts = useCallback(async () => {
    const projectContactData = await GQLClient.graphql({ query: projectContactsByProjectId, variables: { projectId: id } }) as any
    const projectContacts = projectContactData.data.projectContactsByProjectId.items.filter((i: any) => !i._deleted)

    setProjectContacts(draft => {
      draft.splice(0, draft.length)
      draft.push(...projectContacts)
    })
  }, [id, setProjectContacts])

  useEffect(() => {
    fetchProject()
    fetchProjectContacts()
  }, [fetchProject, fetchProjectContacts])

  async function saveAddress(address: AddressInput) {
    const projectData = await GQLClient.graphql({
      query: updateProject, variables: {
        input: {
          id: project!.id,
          _version: project!._version,
          address: {
            ...project!.address,
            ...address
          }
        }
      }
    }) as any

    setProject(projectData.data.updateProject)
    setEditingAddress(false)
  }

  async function addContact(contact: UpdateContactInput, label?: string) {
    const projectContactData = await GQLClient.graphql({
      query: createProjectContact, variables: {
        input: {
          groupId: id,
          projectId: id,
          contactId: contact.id,
          label: label
        }
      }
    }) as any
    const projectContact = projectContactData.data.createProjectContact

    if (!primaryContact) {
      await setPrimaryContactId(projectContact.id)
    }

    setProjectContacts(draft => {
      draft.push(projectContact)
    })
    setAddingContact(false)
  }

  async function deleteContact(contact: UpdateProjectContactInput) {
    const projectContact = projectContacts.find(c => c.id === contact.id)

    if (projectContact) {
      const projectContactIdx = projectContacts.indexOf(projectContact)
      await GQLClient.graphql({
        query: deleteProjectContact, variables: {
          input: {
            id: projectContact.id,
            _version: projectContact._version
          }
        }
      }) as any

      setProjectContacts(draft => {
        draft.splice(projectContactIdx, 1)
      })

      if (contact.id === project!.projectPrimaryContactId) {
        await setPrimaryContactId(undefined)
      }
    }
  }

  async function setPrimaryContactId(projectContact?: ProjectContact) {
    const projectData = await GQLClient.graphql({
      query: updateProject, variables: {
        input: {
          id: project!.id,
          _version: project!._version,
          projectPrimaryContactId: projectContact ? projectContact.contactId : null
        } as UpdateProjectInput
      }
    }) as any

    setProject(projectData.data.updateProject)
  }

  async function setPrimaryEft(user: UpdateUserInput | null) {
    const projectData = await GQLClient.graphql({
      query: updateProject, variables: {
        input: {
          id: project!.id,
          _version: project!._version,
          projectEftId: user?.id || null
        }
      }
    }) as any

    setProject(projectData.data.updateProject)
    setEftPickerOpen(false)
  }

  async function setPrimaryReviewer(user: UpdateUserInput | null) {
    const projectData = await GQLClient.graphql({
      query: updateProject, variables: {
        input: {
          id: project!.id,
          _version: project!._version,
          projectReviewerId: user?.id || null
        }
      }
    }) as any

    setProject(projectData.data.updateProject)
    setReviewerPickerOpen(false)
  }

  async function setProjectAdmin(user: UpdateUserInput | null) {
    const projectData = await GQLClient.graphql({
      query: updateProject, variables: {
        input: {
          id: project!.id,
          _version: project!._version,
          projectAdminId: user?.id || null
        }
      }
    }) as any

    setProject(projectData.data.updateProject)
    setAdminPickerOpen(false)
  }

  const [changes, setChanges] = useState<any>()
  function setProjectValue(event: ChangeEvent<any> | SelectChangeEvent<string | null>) {
    setChanges({
      ...changes,
      [event.target.name]: event.target.value
    })
  }

  function cancelEditingDetails() {
    setChanges(undefined)
    setEditingDetails(false)
  }

  const templates = useActiveMilestoneTemplates(project?.milestoneTemplateId)

  const saveProject = async () => {
    try {
      const projectData = await GQLClient.graphql({
        query: updateProject, variables: {
          input: {
            id: project!.id,
            _version: project!._version,
            ...changes
          }
        }
      }) as any

      setProject(projectData.data.updateProject)
      setEditingDetails(false)
      setChanges(undefined)
    } catch (err) {
      console.log('error saving project:', err)
    }
  }

  if (!project) {
    return <LoadingIndicator />
  }

  return (
    <Grid container spacing={3} alignItems="flex-start">
      <Grid container spacing={3} item xs={6}>
        <Grid item xs={12}>
          <Card variant="outlined">
            <CardHeader style={{ backgroundColor: 'silver' }}
              title='Project'
              titleTypographyProps={{ style: { fontWeight: 'bold', textTransform: 'uppercase', fontSize: 14 } }}
              action={
                <div>
                  {!editingDetails &&
                    <IconButton style={{ margin: 0 }} size="small" onClick={() => setEditingDetails(true)}>
                      <Edit />
                    </IconButton>
                  }
                </div>
              }
            />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    label="Project Name"
                    variant="outlined"
                    size="small"
                    fullWidth
                    name="name"
                    onChange={setProjectValue}
                    value={changes?.name !== undefined ? changes.name : project.name}
                    inputProps={{
                      readOnly: !editingDetails
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl size="small" fullWidth variant="outlined">
                    <InputLabel id="status-label">Status</InputLabel>
                    <Select
                      labelId="status-label"
                      value={changes?.status || project.status}
                      name="status"
                      onChange={setProjectValue}
                      label="Status"
                      readOnly={!editingDetails}
                    >
                      <MenuItem value={ProjectStatus.ACTIVE}>Active</MenuItem>
                      <MenuItem value={ProjectStatus.INACTIVE}>Inactive</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl size="small" fullWidth variant="outlined">
                    <InputLabel id="milestone-label">Milestone Template</InputLabel>
                    <Select
                      labelId="milestone-label"
                      value={changes?.milestoneTemplateId || project.milestoneTemplateId || ''}
                      name="milestoneTemplateId"
                      onChange={setProjectValue}
                      label="MilestoneTemplate"
                      readOnly={!editingDetails}
                    >
                      <MenuItem value="">None</MenuItem>
                      {templates.map(template =>
                        <MenuItem key={template.id} value={template.id}>{template.name}</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Project Path"
                    variant="outlined"
                    size="small"
                    fullWidth
                    name="externalId"
                    onChange={setProjectValue}
                    value={changes?.externalId !== undefined ? changes.externalId : project.externalId}
                    inputProps={{
                      readOnly: !editingDetails
                    }}
                  />
                </Grid>
                {editingDetails &&
                  <Grid item xs={12}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={cancelEditingDetails}
                      style={{ fontWeight: 'bold', textTransform: 'uppercase' }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={saveProject}
                      disabled={!changes || changes.name === ''}
                      style={{ fontWeight: 'bold', textTransform: 'uppercase', marginLeft: 10 }}
                    >
                      Save
                    </Button>
                  </Grid>
                }
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card variant="outlined">
            <CardHeader style={{ backgroundColor: 'silver' }}
              title='Location'
              titleTypographyProps={{ style: { fontWeight: 'bold', textTransform: 'uppercase', fontSize: 14 } }}
              action={
                <div>
                  {!editingAddress &&
                    <IconButton style={{ margin: 0 }} size="small" onClick={() => setEditingAddress(true)}>
                      <Edit />
                    </IconButton>
                  }
                </div>
              }
            />
            <CardContent>
              <AddressEditor
                address={project.address as AddressInput}
                editing={editingAddress}
                onSave={(a: AddressInput) => saveAddress(a)}
                onCancel={() => setEditingAddress(false)} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <ExternalLinks projectId={id} />
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Card variant="outlined">
          <CardHeader style={{ backgroundColor: 'silver' }}
            title='Team'
            titleTypographyProps={{ style: { fontWeight: 'bold', textTransform: 'uppercase', fontSize: 14 } }}
          />
          <CardContent>
            <List
              component="div"
              dense={true}
            >
              <ListSubheader style={{ textTransform: 'uppercase', fontWeight: 500 }}>
                Primary EFT
              </ListSubheader>
              {eft &&
                <ProjectUser
                  item={eft}
                  onChange={() => setEftPickerOpen(true)}
                  onDelete={() => setPrimaryEft(null)}
                />
              }
              {!eft &&
                <ListItem style={{ justifyContent: 'center' }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => { setEftPickerOpen(true) }}
                  >
                    Select
                  </Button>
                </ListItem>
              }
              <ListSubheader style={{ textTransform: 'uppercase', fontWeight: 500 }}>
                Primary Reviewer
              </ListSubheader>
              {reviewer &&
                <ProjectUser
                  item={reviewer}
                  onChange={() => setReviewerPickerOpen(true)}
                  onDelete={() => setPrimaryReviewer(null)}
                />
              }
              {!reviewer &&
                <ListItem style={{ justifyContent: 'center' }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => { setReviewerPickerOpen(true) }}
                  >
                    Select
                  </Button>
                </ListItem>
              }
              <ListSubheader style={{ textTransform: 'uppercase', fontWeight: 500 }}>
                Project Admin
              </ListSubheader>
              {admin &&
                <ProjectUser
                  item={admin}
                  onChange={() => setAdminPickerOpen(true)}
                  onDelete={() => setProjectAdmin(null)}
                />
              }
              {!admin &&
                <ListItem style={{ justifyContent: 'center' }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => { setAdminPickerOpen(true) }}
                  >
                    Select
                  </Button>
                </ListItem>
              }
            </List>
            <UserPicker
              open={eftPickerOpen}
              onClose={() => setEftPickerOpen(false)}
              onSelect={setPrimaryEft}
            />
            <UserPicker
              open={reviewerPickerOpen}
              onClose={() => setReviewerPickerOpen(false)}
              onSelect={setPrimaryReviewer}
            />
            <UserPicker
              open={adminPickerOpen}
              onClose={() => setAdminPickerOpen(false)}
              onSelect={setProjectAdmin}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card variant="outlined">
          <CardHeader style={{ backgroundColor: 'silver' }}
            title='Contacts'
            titleTypographyProps={{ style: { fontWeight: 'bold', textTransform: 'uppercase', fontSize: 14 } }}
            action={
              <IconButton style={{ margin: 0 }} size="small" onClick={() => setAddingContact(true)}>
                <Add />
              </IconButton>
            }
          />
          <CardContent>
            <ContactList items={projectContacts} primaryId={primaryContact?.id} onDelete={deleteContact} onSetPrimary={(item: any) => setPrimaryContactId(item)} />
            <ContactPicker
              open={addingContact}
              onClose={() => setAddingContact(false)}
              onSelect={addContact}
              projectClientId={clientId}
              projectContacts={projectContacts}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}