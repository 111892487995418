import React, { useEffect, useState } from "react"
import { Box, Typography } from "@mui/material"
import { CheckCircle } from "@mui/icons-material";
import { ObservationStatus } from "../models";

type Props = {
  item: any,
  onClick: Function
};

export const ConformingLineItem: React.FC<Props> = (props) => {
  const { item, onClick } = props

  const [borderColor, setBorderColor] = useState<string>('')
  const [borderWidth, setBorderWidth] = useState<number>(1)
  useEffect(() => {
    if (item.status === ObservationStatus.ACCEPTED) {
      setBorderColor('green')
      setBorderWidth(2)
    } else if (item.status === ObservationStatus.DECLINED) {
      setBorderColor('red')
      setBorderWidth(2)
    } else {
      setBorderColor('rgba(0, 0, 0, 0.12)')
      setBorderWidth(1)
    }
  }, [item])

  return (
    <Box display="flex" flexDirection="row" onClick={() => onClick(item)} flex={1} style={{
      borderWidth: borderWidth,
      borderStyle: 'solid',
      borderColor: borderColor,
      padding: 10,
      borderRadius: 8,
      marginBottom: 5,
      cursor: 'pointer'
    }}>
      <Box display="flex" flexDirection="column" style={{ marginRight: 10 }} justifyContent="center" alignItems="center">
        <CheckCircle fontSize="large" style={{ color: "green" }} />
        <Typography style={{ fontSize: 12, fontWeight: 'bold' }}>{item.date}</Typography>
        <Typography style={{ fontSize: 12, fontWeight: 'bold' }}>{item.code}</Typography>
      </Box>
      <Box display="flex" flexDirection="column" justifyContent="center">
        <Typography style={{ fontSize: 16 }}>{item.text}</Typography>
        <Typography style={{ fontSize: 14, fontStyle: 'italic' }}>{item.inspectionPoint ? item.inspectionPoint.text : ''}</Typography>
      </Box>
    </Box>
  )
}