import React, { useCallback, useEffect, useState } from "react"
import { Box, Typography } from "@mui/material"
import { FindingType, UpdateFindingInput } from "../API";
import GQLClient from "../GQLClient";
import { getFinding } from "../graphql/queries";
import { Help, Error, Brightness1 as Circle } from "@mui/icons-material";
import { ObservationStatus } from "../models";

type Props = {
  item: any
  onClick: Function
};

export const FindingLineItem: React.FC<Props> = (props) => {
  const { item, onClick } = props

  const [finding, setFinding] = useState<UpdateFindingInput>()
  const fetchFinding = useCallback(async () => {
    const findingData = await GQLClient.graphql({ query: getFinding, variables: { id: item.findingId! } }) as any
    const finding = findingData.data.getFinding as any
    setFinding(finding)
  }, [item])

  useEffect(() => {
    fetchFinding()
  }, [fetchFinding])

  const [borderColor, setBorderColor] = useState<string>('')
  const [borderWidth, setBorderWidth] = useState<number>(1)
  useEffect(() => {
    if (item.status === ObservationStatus.ACCEPTED) {
      setBorderColor('green')
      setBorderWidth(2)
    } else if (item.status === ObservationStatus.DECLINED) {
      setBorderColor('red')
      setBorderWidth(2)
    } else {
      setBorderColor('rgba(0, 0, 0, 0.12)')
      setBorderWidth(1)
    }
  }, [item])

  return (
    <Box display="flex" flexDirection="row" onClick={() => onClick(item)} flex={1} style={{
      borderWidth: borderWidth,
      borderStyle: 'solid',
      borderColor: borderColor,
      padding: 10,
      borderRadius: 8,
      marginBottom: 5,
      cursor: 'pointer'
    }}>
      <Box display="flex" flexDirection="column" style={{ marginRight: 10 }} justifyContent="center" alignItems="center">
        {finding?.type === FindingType.IOC &&
          <Help fontSize="large" style={{ color: "#FFB900" }} />
        }
        {finding?.type === FindingType.NCI &&
          <Error fontSize="large" style={{ color: "red" }} />
        }
        {!finding &&
          <Circle fontSize="large" style={{ color: "#C0C0C0" }} />
        }
        {finding?.date &&
          <Typography style={{ fontSize: 12, fontWeight: 'bold' }}>{new Date(finding.date).toLocaleDateString()}</Typography>
        }
        <Typography style={{ fontSize: 12, fontWeight: 'bold' }}>{finding?.code}</Typography>
      </Box>
      <Box display="flex" flexDirection="column" justifyContent="center">
        <Typography style={{ fontSize: 16 }}>{item.primaryLocation.primaryPhoto ? item.primaryLocation.primaryPhoto.text : ''}</Typography>
        <Typography style={{ fontSize: 14, fontStyle: 'italic' }}>{item.inspectionPoint ? item.inspectionPoint.text : ''}</Typography>
      </Box>
    </Box>
  )
}