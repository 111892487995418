import React, { useCallback, useEffect, useState } from 'react';
import GQLClient from '../GQLClient';
import { DataGrid } from '../common/DataGrid';
import { phasesByProjectId } from '../graphql/queries';
import { UpdatePhaseInput } from '../API';
import { ColDef } from 'ag-grid-community';

type Props = {
  id: string,
  projectId: string
  children?: React.ReactNode
  onSelect?: Function
  columnDefs?: ColDef[]
};

export const PhasesGrid: React.FC<Props> = (props) => {
  const { id, projectId, children, onSelect, columnDefs } = props

  const [data, setData] = useState<UpdatePhaseInput[]>()

  const fetchPhases = useCallback(async () => {
    const phasesData = await GQLClient.graphql({
      query: phasesByProjectId, variables: {
        projectId: projectId
      }
    }) as any
    const phases = phasesData.data.phasesByProjectId.items

    setData(phases)
  }, [projectId, setData])

  useEffect(() => {
    fetchPhases()
  }, [fetchPhases])


  return (
    <DataGrid id={id} data={data} onSelect={onSelect} columnDefs={columnDefs}>
      {children}
    </DataGrid>
  )
}

export default PhasesGrid