import React, { useCallback, useEffect, useState } from 'react';
import { useImmer } from 'use-immer';
import { Card, CardContent, CardHeader, Grid, IconButton } from '@mui/material';

import { useParams } from 'react-router-dom';
import { Add, Edit } from '@mui/icons-material';
import { AddressEditor } from '../common/AddressEditor';
import { createClientContact, deleteClientContact, updateClient } from '../graphql/mutations';
import { ClientContact, ContactList } from '../contact/ContactList';
import { ContactPicker } from '../contact/ContactPicker';
import { Address } from '../models';
import { getClient } from '../graphql/queries';
import { UpdateClientContactInput, UpdateClientInput, UpdateContactInput } from '../API';
import LoadingIndicator from '../common/LoadingIndicator';
import GQLClient from '../GQLClient';

export const clientContactsByClientId = /* GraphQL */ `
  query ClientContactsByClientId(
    $clientId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelClientContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    clientContactsByClientId(
      clientId: $clientId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        contactId
        contact {
          id
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          _version
          _deleted
          createdAt
          updatedAt
          owner
        }
        label
        _version
        _deleted
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;

export default function ClientDetails() {
  const { id } = useParams() as any;
  const [client, setClient] = useState<UpdateClientInput>()
  const [primaryContact, setPrimaryContact] = useState<UpdateContactInput>()
  const [clientContacts, setClientContacts] = useImmer<ClientContact[]>([])
  const [editingAddress, setEditingAddress] = useState(false);
  const [addingContact, setAddingContact] = useState(false);

  const fetchClient = useCallback(async () => {
    const clientData = await GQLClient.graphql({ query: getClient, variables: { id: id } }) as any
    setClient(clientData.data.getClient)
  }, [id, setClient])

  const fetchClientContacts = useCallback(async () => {
    const clientContactsData = await GQLClient.graphql({ query: clientContactsByClientId, variables: { clientId: id } }) as any
    const clientContacts = clientContactsData.data.clientContactsByClientId.items.filter((i: any) => !i._deleted)

    setClientContacts(draft => {
      draft.splice(0, draft.length)
      draft.push(...clientContacts)
    })
  }, [id, setClientContacts])

  useEffect(() => {
    fetchClient()
    fetchClientContacts()
  }, [fetchClient, fetchClientContacts])

  useEffect(() => {
    const rawClient = client as any
    setPrimaryContact(rawClient?.primaryContact)
  }, [client])

  async function saveAddress(address: Address) {
    const clientData = await GQLClient.graphql({
      query: updateClient, variables: {
        input: {
          id: id,
          address: {
            ...client!.address,
            ...address
          },
          _version: client!._version
        }
      }
    }) as any

    setClient(clientData.data.updateClient)
    setEditingAddress(false)
  }


  async function addContact(contact: UpdateContactInput, label?: string) {
    const clientContactData = await GQLClient.graphql({
      query: createClientContact, variables: {
        input: {
          clientId: id,
          contactId: contact.id,
          label: label
        }
      }
    }) as any
    const clientContact = clientContactData.data.createClientContact

    if (!primaryContact) {
      await setPrimaryContactId(clientContact)
    }

    setClientContacts(draft => {
      draft.push(clientContact)
    })
    setAddingContact(false)
  }

  async function deleteContact(contact: UpdateClientContactInput) {
    const clientContact = clientContacts.find(c => c.id === contact.id)

    if (clientContact) {
      const clientContactIdx = clientContacts.indexOf(clientContact)
      await GQLClient.graphql({
        query: deleteClientContact, variables: {
          input: {
            id: clientContact.id,
            _version: clientContact._version
          }
        }
      }) as any

      setClientContacts(draft => {
        draft.splice(clientContactIdx, 1)
      })

      if (contact.id === client!.clientPrimaryContactId) {
        await setPrimaryContactId(undefined)
      }
    }
  }

  async function setPrimaryContactId(clientContact?: ClientContact) {
    const clientData = await GQLClient.graphql({
      query: updateClient, variables: {
        input: {
          id: client!.id,
          _version: client!._version,
          clientPrimaryContactId: clientContact ? clientContact.contactId : null
        } as UpdateClientInput
      }
    }) as any

    setClient(clientData.data.updateClient)
  }

  if (!client) {
    return <LoadingIndicator />
  }

  return (
    <Grid container spacing={3} item xs={12}>
      <Grid item xs={6}>
        <Card variant="outlined">
          <CardHeader style={{ backgroundColor: 'silver' }}
            title='Location'
            titleTypographyProps={{ style: { fontWeight: 'bold', textTransform: 'uppercase', fontSize: 14 } }}
            action={
              <div>
                {!editingAddress &&
                  <IconButton style={{ margin: 0 }} size="small" onClick={() => setEditingAddress(true)}>
                    <Edit />
                  </IconButton>
                }
              </div>
            }
          />
          <CardContent>
            <AddressEditor
              address={client.address!}
              editing={editingAddress}
              onSave={(a: Address) => saveAddress(a)}
              onCancel={() => setEditingAddress(false)} />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card variant="outlined">
          <CardHeader style={{ backgroundColor: 'silver' }}
            title='Contacts'
            titleTypographyProps={{ style: { fontWeight: 'bold', textTransform: 'uppercase', fontSize: 14 } }}
            action={
              <IconButton style={{ margin: 0 }} size="small" onClick={() => setAddingContact(true)}>
                <Add />
              </IconButton>
            }
          />
          <CardContent>
            <ContactList items={clientContacts} primaryId={primaryContact?.id} onDelete={deleteContact} onSetPrimary={(item: any) => setPrimaryContactId(item)} />
            <ContactPicker open={addingContact} onClose={() => setAddingContact(false)} onSelect={addContact} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}