import React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { Paper, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

type Props = {
  activityStreamItems: any[]
};

export const ActivityStream: React.FC<Props> = (props) => {
  return (
    <Timeline position="alternate">
      {props.activityStreamItems.map((a: any) =>
        <TimelineItem key={a.id}>
          <TimelineOppositeContent>
            <Typography variant="body1" color="textSecondary">
              {new Date(a.date).toLocaleString()}
            </Typography>
            <Typography variant="body1" color="textSecondary">
              {a.user.firstName + ' ' + a.user.lastName}
            </Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot color="primary">
              <InfoIcon />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Paper elevation={3} style={{ backgroundColor: 'silver' }}>
              <Typography style={{ padding: 6 }} variant='body1'>{a.text}</Typography>
            </Paper>
          </TimelineContent>
        </TimelineItem>
      )}
    </Timeline>
  )
}