import React, { useState } from "react";
import { Button, ButtonProps, CircularProgress } from "@mui/material";


export const LoadingButton: React.FC<ButtonProps> = (props) => {
  const [loading, setLoading] = useState<boolean>(false)
  const { onClick } = props

  const _onClick = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setLoading(true)
    try {
      if (onClick) {
        await onClick(event)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <Button {...props} disabled={props.disabled || loading} onClick={_onClick}>
      {props.children}
      {loading && <CircularProgress size={24} style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
      }}
      />}
    </Button>
  )
}