import React, { useEffect, useState } from 'react';
import { Button, Card, CardContent, CardHeader, Grid, IconButton, TextField } from '@mui/material';

import { FindingStatus, UpdateHierarchyItemInput, UpdateInspectionHierarchyItemInput, UpdateObservationInput } from '../API';
import { FindingLineItem } from './FindingLineItem';
import { ResolutionLineItem } from './ResolutionLineItem';
import { Edit } from '@mui/icons-material';
import GQLClient from '../GQLClient';
import { updateInspectionHierarchyItem } from '../graphql/mutations';
import { LoadingButton } from '../common/LoadingButton';

type Props = {
  item: HierarchyItemFindings,
  editable?: boolean
  onClick: Function
};

export type HierarchyItemFindings = {
  id: string
  inspectionHierarchyItem?: UpdateInspectionHierarchyItemInput
  hierarchyItem?: UpdateHierarchyItemInput
  findings: any[]
}

export const HierarchyItemFinding: React.FC<Props> = (props) => {
  const { item, editable, onClick } = props
  const [editing, setEditing] = useState<boolean>(false)
  const [saving, setSaving] = useState<boolean>(false)
  const save = async () => {
    if (!item.inspectionHierarchyItem) return

    try {
      setSaving(true)
      const inspectionHierarchyItemData = await GQLClient.graphql({
        query: updateInspectionHierarchyItem, variables: {
          input: {
            id: item.inspectionHierarchyItem.id,
            _version: item.inspectionHierarchyItem._version,
            ...changes
          }
        }
      }) as any

      item.inspectionHierarchyItem = inspectionHierarchyItemData.data.updateInspectionHierarchyItem
      setChanges(undefined)
      setEditing(false)
    } finally {
      setSaving(false)
    }
  }

  const cancel = () => {
    setChanges(undefined)
    setNotes(item.inspectionHierarchyItem?.notes || '')
    setEditing(false)
  }

  const [changes, setChanges] = useState<any>()
  const [notes, setNotes] = useState<string>()
  useEffect(() => {
    setNotes(item.inspectionHierarchyItem?.notes || '')
  }, [item])

  const handleChange = (text: string) => {
    setChanges({
      ...changes,
      notes: text
    })
    setNotes(text)
  }

  const selectObservation = (item: UpdateObservationInput) => {
    onClick(item)
  }

  return (
    <div style={{ marginBottom: 10 }}>
      <Card variant="outlined" style={{ width: '100%', marginBottom: 5 }}>
        <CardHeader style={{ backgroundColor: '#EF8354', color: '#ffffff' }}
          title={item.hierarchyItem?.code + ' - ' + item.hierarchyItem?.name}
          titleTypographyProps={{ style: { fontWeight: 'bold', textTransform: 'uppercase', fontSize: 14 } }}
          action={
            <div>
              {editable && !editing && item.inspectionHierarchyItem?.notes &&
                <IconButton style={{ margin: 0, color: '#000000' }} size="small" onClick={() => setEditing(true)}>
                  <Edit htmlColor="#ffffff" />
                </IconButton>
              }
            </div>
          }
        />
        {item.inspectionHierarchyItem?.notes &&
          <CardContent style={{ padding: 10 }}>
            <Grid item xs={12}>
              <TextField
                name="notes"
                label="Stage Notes"
                fullWidth
                variant="outlined"
                multiline
                rows={4}
                maxRows={4}
                value={notes}
                onChange={(event: any) => handleChange(event.target.value)}
                InputProps={{
                  readOnly: !editing || saving,
                }}
              />
              {editing &&
                <Grid item xs={12} style={{ marginTop: 20 }}>
                  <Button
                    style={{ marginRight: 20 }}
                    variant="outlined"
                    color="primary"
                    disabled={saving}
                    onClick={() => cancel()}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    onClick={() => save()}
                    disabled={!changes || saving}
                  >
                    Save
                  </LoadingButton>
                </Grid>
              }
            </Grid>
          </CardContent>
        }
      </Card>
      {item.findings.map(f => {
        if (f.status === FindingStatus.CLOSED && f.closingObservation) {
          return <ResolutionLineItem key={f.id} item={f.closingObservation} onClick={selectObservation} />
        } else if (f.status === FindingStatus.OPEN || f.status === FindingStatus.NEW) {
          return <FindingLineItem key={f.id} item={f.observation} onClick={selectObservation} />
        } else {
          return <></>
        }
      })}
    </div>
  );
}
