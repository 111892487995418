import React, { ChangeEvent, useState } from 'react';
import { Avatar, Button, FormControl, Grid, InputLabel, ListItem, ListItemAvatar, ListItemText, OutlinedInput, TextField } from '@mui/material';
import { CreateContactInput, UpdateContactInput } from '../API';
import MaskedInput from 'react-text-mask'

interface TextMaskProps {
  inputRef?: (ref: HTMLInputElement | null) => void;
}

export const PhoneInput: React.FC<TextMaskProps> = (props) => {
  const { inputRef = () => { }, ...other } = props;

  return (
    (<MaskedInput
              {...other}
              ref={(ref: any) => {
                inputRef(ref ? ref.inputElement : null);
              }}
              mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            />)
  );
}


export const ContactEditor: React.FC<{ contact: CreateContactInput | UpdateContactInput, editing: boolean, onSave?: Function, onCancel?: Function, onChange?: Function }> = ({ contact, editing = false, onSave, onCancel, onChange }) => {
  const [_contact, setContact] = useState<CreateContactInput | UpdateContactInput>(contact)
  const [changes, setChanges] = useState<any>(null)

  function setContactValue(event: ChangeEvent<HTMLInputElement>) {
    const newChanges = {
      ...changes,
      [event.target.name]: event.target.value
    }

    setChanges(newChanges)

    setContact({
      ..._contact,
      [event.target.name]: event.target.value
    })

    onChange && onChange(newChanges)
  }

  function save() {
    onSave && onSave(changes)
    setChanges(null)
  }

  function cancel() {
    setContact(contact)
    setChanges(null)
    onCancel && onCancel()
  }

  if (!editing) {
    return (
      <ListItem alignItems="flex-start" style={{ alignItems: 'center' }}>
        <ListItemAvatar>
          <Avatar>
            {`${_contact.firstName ? _contact.firstName[0] : ''} ${_contact.lastName ? _contact.lastName[0] : ''}`}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={`${_contact.firstName} ${_contact.lastName}`}
          secondary={_contact.companyName}
        />
        <ListItemText
          primary={_contact.email}
          secondary={_contact.phone}
        />
      </ListItem>
    )
  }

  function valid() {
    return changes !== null && changes !== undefined
      && (changes.firstName === undefined || changes.firstName)
      && (changes.lastName === undefined || changes.lastName)
      && (changes.firstName === undefined || changes.firstName)
      && (changes.companyName === undefined || changes.companyName)
      //&& (changes.email === undefined || changes.email)
      && (!changes.phone || changes.phone.replace(/\D/g, "").length === 10);
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          label="First Name"
          variant="outlined"
          size="small"
          fullWidth
          name="firstName"
          onChange={setContactValue}
          value={_contact.firstName || ''}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Last Name"
          variant="outlined"
          size="small"
          fullWidth
          name="lastName"
          onChange={setContactValue}
          value={_contact.lastName || ''}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Company Name"
          variant="outlined"
          size="small"
          fullWidth
          name="companyName"
          onChange={setContactValue}
          value={_contact.companyName || ''}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Job Title"
          variant="outlined"
          size="small"
          fullWidth
          name="jobTitle"
          onChange={setContactValue}
          value={_contact.jobTitle || ''}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Email Address"
          variant="outlined"
          size="small"
          fullWidth
          name="email"
          onChange={setContactValue}
          value={_contact.email || ''}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl size="small" fullWidth>
          <InputLabel style={{ margin: '-8px 0 0 14px' }}>Phone</InputLabel>
          <OutlinedInput
            label="Phone"
            name="phone"
            onChange={setContactValue}
            value={_contact.phone || ''}
            inputComponent={PhoneInput as any}
          />
        </FormControl>
      </Grid>
      {(onSave || onCancel) &&
        <Grid item xs={12}>
          {onCancel &&
            <Button
              variant="outlined"
              color="primary"
              onClick={cancel}
              style={{ fontWeight: 'bold', textTransform: 'uppercase' }}
            >
              Cancel
            </Button>
          }
          {onSave &&
            <Button
              variant="contained"
              color="primary"
              disabled={!valid()}
              onClick={save}
              style={{ fontWeight: 'bold', textTransform: 'uppercase', marginLeft: 10 }}
            >
              Save
            </Button>
          }
        </Grid>
      }
    </Grid>
  )
}