import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import GQLClient from '../GQLClient';
import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';

import { useParams } from 'react-router-dom';
import { getMilestoneTemplate, hierarchyBundlesByType } from '../graphql/queries';
import { UpdateHierarchyBundleInput, UpdateMilestoneTemplateInput } from '../API';
import { HierarchyBundleStatus, HierarchyBundleType, MilestoneTemplateStatus } from '../models';
import { updateMilestoneTemplate } from '../graphql/mutations';
import LoadingIndicator from '../common/LoadingIndicator';
import { LoadingButton } from '../common/LoadingButton';
import ReactSelect from 'react-select';

export default function MilestoneBundleView() {
  const { id } = useParams() as any;

  const [template, setTemplate] = useState<UpdateMilestoneTemplateInput>()
  const [changes, setChanges] = useState<any>()

  const fetchTemplate = useCallback(async () => {
    const templateData = await GQLClient.graphql({ query: getMilestoneTemplate, variables: { id: id } }) as any
    const template = templateData.data.getMilestoneTemplate
    setTemplate(template)
  }, [id])

  useEffect(() => {
    fetchTemplate()
  }, [fetchTemplate])

  function setTemplateValue(event: ChangeEvent<any> | SelectChangeEvent<string | null>) {
    setChanges({
      ...changes,
      [event.target.name]: event.target.value
    })

    setTemplate({
      ...template,
      [event.target.name]: event.target.value
    } as UpdateMilestoneTemplateInput)
  }

  async function fetchBundles() {
    try {
      const bundlesData = await GQLClient.graphql({
        query: hierarchyBundlesByType, variables: {
          type: HierarchyBundleType.MILESTONE
        }
      }) as any
      const bundles = bundlesData.data.hierarchyBundlesByType.items
      setBundles(bundles.map((bundle: UpdateHierarchyBundleInput) => {
        return {
          value: bundle.id,
          label: bundle.name,
          isDisabled: bundle.status !== HierarchyBundleStatus.ACTIVE
        }
      }))
    } catch (err) { console.log('error fetching bundles') }
  }

  const [bundles, setBundles] = useState<any[]>([])
  useEffect(() => {
    fetchBundles()
  }, [])

  const [selectedBundles, setSelectedBundles] = useState<string[]>([])
  useEffect(() => {
    if (bundles.length && template?.hierarchyBundleIds) {
      const hierarchyBundleIds = JSON.parse(template.hierarchyBundleIds)
      const selectedBundles: any[] = []
      hierarchyBundleIds.forEach((bundleId: string) => {
        const bundle = bundles.find(b => b.value === bundleId)
        if (bundle) {
          selectedBundles.push(bundle)
        }
      })
      setSelectedBundles(selectedBundles)
    } else {
      setSelectedBundles([])
    }
  }, [template, bundles])


  const onBundleChange = (value: any) => {
    setSelectedBundles(value)
    setChanges({
      ...changes,
      'hierarchyBundleIds': JSON.stringify(value.map((v: any) => v.value))
    })
  };

  const saveTemplate = async () => {
    try {
      const templateData = await GQLClient.graphql({
        query: updateMilestoneTemplate, variables: {
          input: {
            id: template!.id,
            _version: template!._version,
            ...changes
          }
        }
      }) as any

      setTemplate(templateData.data.updateMilestoneTemplate)
    } catch (err) {
      console.log('error saving milestone template:', err)
    }
  }

  if (!template) {
    return <LoadingIndicator />
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5" style={{ fontWeight: 'bold' }}>
          Milestone Template
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <TextField
          label="Template Name"
          variant="outlined"
          size="small"
          fullWidth
          name="name"
          onChange={setTemplateValue}
          value={template.name}
        />
      </Grid>
      <Grid item xs={4}>
        <FormControl size="small" fullWidth variant="outlined">
          <InputLabel id="status-label">Status</InputLabel>
          <Select
            labelId="status-label"
            value={template.status}
            name="status"
            onChange={setTemplateValue}
            label="Status"
          >
            <MenuItem value={MilestoneTemplateStatus.ACTIVE}>Active</MenuItem>
            <MenuItem value={MilestoneTemplateStatus.INACTIVE}>Inactive</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Typography style={{ fontWeight: 'bold' }}>
          Milestone Bundles
        </Typography>
        <ReactSelect
          isMulti
          name="hierarchyBundleIds"
          onChange={onBundleChange}
          value={selectedBundles}
          options={bundles}
          className="basic-multi-select"
          classNamePrefix="select"
          placeholder="Select Bundles"
        />
      </Grid>
      <Grid item xs={4}>
        <LoadingButton
          variant="contained"
          color="primary"
          onClick={saveTemplate}
          disabled={!changes}
          style={{ fontWeight: 'bold', textTransform: 'uppercase' }}
        >
          Save
        </LoadingButton>
      </Grid>
    </Grid>
  );
}