import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Tab, Tabs, Typography } from '@mui/material';
import GQLClient from '../GQLClient';

import { useParams } from 'react-router-dom';
import { getClient } from '../graphql/queries';
import ClientDetails from './ClientDetails';
import ClientProjects from './ClientProjects';
import { UpdateClientInput } from '../API';
import ClientReportSettings from './ClientReportSettings';
import LoadingIndicator from '../common/LoadingIndicator';



export default function ClientView() {
  const { id } = useParams() as any;

  const [client, setClient] = useState<UpdateClientInput>()
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  const fetchClient = useCallback(async () => {
    const clientData = await GQLClient.graphql({ query: getClient, variables: { id: id } }) as any
    setClient(clientData.data.getClient)
  }, [id])

  useEffect(() => {
    fetchClient()
  }, [fetchClient])

  if (!client) {
    return <LoadingIndicator />
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5" style={{ fontWeight: 'bold' }}>
          {client.name}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Tabs value={selectedTab} onChange={handleTabChange} >
          <Tab label="Details" />
          <Tab label="Projects" />
          <Tab label="Report Settings" />
        </Tabs>
      </Grid>
      <Grid item xs={12}>
        {selectedTab === 0 && <ClientDetails />}
        {selectedTab === 1 && <ClientProjects />}
        {selectedTab === 2 && <ClientReportSettings />}
      </Grid>
    </Grid>
  );
}