import React from 'react';
import { Routes, Route, Link, useMatch, useLocation } from 'react-router-dom';
import { ThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton'
import ListItemButton from '@mui/material/ListItemButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { AccountCircle } from '@mui/icons-material';
import Dashboard from './dashboard/Dashboard';
import Inspections from './inspection/Inspections';
import Projects from './project/Projects';
import Clients from './client/Clients';
import Team from './team/Team';
import NewClient from './client/NewClient';
import ClientView from './client/ClientView';
import NewProject from './project/NewProject';
import NewInspection from './inspection/NewInspection';
import ProjectView from './project/ProjectView';
import { Contacts } from './contact/Contacts';
import { NewContact } from './contact/NewContact';
import ContactView from './contact/ContactView';
import HierarchyView from './hierarchy/HierarchyView';
import Logo from './assets/criterium-triangle.svg';
import { useTheme, View, withAuthenticator, WithAuthenticatorProps } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { NewUser } from './team/NewUser';
import { UserView } from './team/UserView';
import PhaseView from './phase/PhaseView';
import { NewPhase } from './phase/NewPhase';
import InspectionView from './inspection/InspectionView';
import MilestoneTemplates from './milestone/MilestoneTemplates';
import MilestoneBundles from './milestone/MilestoneBundles';
import { NewMilestoneBundle } from './milestone/NewMilestoneBundle';
import MilestoneBundleView from './milestone/MilestoneBundleView';
import { NewMilestoneTemplate } from './milestone/NewMilestoneTemplate';
import MilestoneTemplateView from './milestone/MilestoneTemplateView';


const drawerWidth = 200;

const theme = createTheme({
  palette: {
    primary: {
      main: '#3f51b5'
    },
    secondary: {
      main: '#F50057'
    }
  },
  components: {
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: '#f50057',
          height: 4,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minWidth: 0, // Remove default min-width to allow for even spacing
          padding: '0 45px',
          textAlign: 'center', // Center-align the text within each tab
          fontSize: '1rem',
          fontWeight: 500,
          letterSpacing: '0.05em',
          color: 'rgba(0, 0, 0, 0.7)',
          '&.Mui-selected': {
            color: '#000000',
          },
          '&:hover': {
            color: '#000000',
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: '#F50057'
          }
        }
      }
    }
  },
});

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     root: {
//       display: 'flex',
//     },
//     appBar: {
//       zIndex: theme.zIndex.drawer + 1,
//       background: '#333333'
//     },
//     drawer: {
//       width: drawerWidth,
//       flexShrink: 0
//     },
//     drawerPaper: {
//       width: drawerWidth,
//       paddingTop: 80,
//       background: '#2143AB',
//       color: '#ffffff'
//     },
//     content: {
//       flexGrow: 1,
//       padding: theme.spacing(3),
//     },
//     grow: {
//       flexGrow: 1,
//     },
//     navLink: {
//       color: '#ffffff',
//       fontWeight: 500,
//       textDecoration: 'none'
//     },
//     navLinkSelected: {
//       backgroundColor: '#305EEC',
//       '&:hover': {
//         backgroundColor: '#305EEC'
//       },
//       '& $navLink': {
//         fontWeight: 'bold'
//       }
//     }
//   }),
// );

function NavLink({ label, to }: any) {
  const location = useLocation();
  const isSelected = location.pathname.startsWith(to);

  return (
    <Link
      to={to}
      style={{ textDecoration: 'none' }}
    >
      <ListItemButton
        selected={isSelected}
        sx={{
          paddingLeft: 2,
          backgroundColor: isSelected ? '#305EEC' : 'inherit',
          '&:hover': {
            backgroundColor: '#305EEC',
          },
          '& .MuiTypography-root': {
            fontWeight: isSelected ? 'bold' : 'normal',
            color: '#ffffff',
          },
        }}
        key={to}
      >
        <ListItemText
          primary={label}
          sx={{
            color: 'inherit', // Inherit the color from ListItemButton
          }}
        />
      </ListItemButton>
    </Link>
  );
}




interface Props extends WithAuthenticatorProps {
  isPassedToWithAuthenticator: boolean;
}

function App({ isPassedToWithAuthenticator, signOut }: Props) {
  if (!isPassedToWithAuthenticator) {
    throw new Error(`isPassedToWithAuthenticator was not provided`);
  }
  // const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={signOut}>Logout</MenuItem>
    </Menu>
  );


  return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box sx={{ display: 'flex' }}>
          <AppBar
            position="fixed"
            sx={{
              zIndex: theme.zIndex.drawer + 1,
              background: '#333333',
            }}
          >
            <Toolbar>
              <Box sx={{ display: 'flex' }}>
                <img src={Logo} alt="" height={40} />
                <Typography sx={{ marginLeft: 2, fontWeight: 'bold' }} variant="h4">
                  CRISP
                </Typography>
              </Box>
              <Box sx={{ flexGrow: 1 }} />
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </Toolbar>
          </AppBar>
          {renderMenu}
          <Drawer
            variant="permanent"
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                paddingTop: 10,
                background: '#2143AB',
                color: '#ffffff',
              },
              '& .MuiListItemText-root': {
                color: '#ffffff', // Ensure the ListItemText is white
              },
            }}
          >
            <List>
              <NavLink label="Dashboard" to="/dashboard" activeOnlyWhenExact={true} />
              <NavLink label="Inspections" to="/inspections" activeOnlyWhenExact={true} />
              <NavLink label="Projects" to="/projects" activeOnlyWhenExact={true} />
              <NavLink label="Clients" to="/clients" activeOnlyWhenExact={true} />
              <NavLink label="Contacts" to="/contacts" activeOnlyWhenExact={true} />
              <NavLink label="Team" to="/team" activeOnlyWhenExact={true} />
              <NavLink label="Hierarchy" to="/hierarchy" activeOnlyWhenExact={true} />
              <ListItem>
                <ListItemText sx={{ paddingLeft: 1 }} primary="Milestones" />
              </ListItem>
              <Box sx={{ paddingLeft: 2 }}>
                <NavLink label="Bundles" to="/milestones/bundles" activeOnlyWhenExact={true} />
              </Box>
              <Box sx={{ paddingLeft: 2 }}>
                <NavLink label="Templates" to="/milestones/templates" activeOnlyWhenExact={true} />
              </Box>
            </List>
          </Drawer>

          <Box
            component="main"
            sx={{
              flexGrow: 1,
              padding: theme.spacing(3),
            }}
          >
            <Toolbar />
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/inspections/*" element={<Inspections />} />
              <Route path="/newInspection" element={<NewInspection />} />
              <Route path="/inspection/:id/*" element={<InspectionView />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/projects/new" element={<NewProject />} />
              <Route path="/projects/:id" element={<ProjectView />} />
              <Route path="/projects/:id/phase" element={<NewPhase />} />
              <Route path="/clients" element={<Clients />} />
              <Route path="/clients/new" element={<NewClient />} />
              <Route path="/clients/:id" element={<ClientView />} />
              <Route path="/contacts" element={<Contacts />} />
              <Route path="/contacts/new" element={<NewContact />} />
              <Route path="/contacts/:id" element={<ContactView />} />
              <Route path="/team" element={<Team />} />
              <Route path="/team/new" element={<NewUser />} />
              <Route path="/team/:id" element={<UserView />} />
              <Route path="/hierarchy" element={<HierarchyView />} />
              <Route path="/phases/:id" element={<PhaseView />} />
              <Route path="/milestones/templates" element={<MilestoneTemplates />} />
              <Route path="/milestones/templates/new" element={<NewMilestoneTemplate />} />
              <Route path="/milestones/templates/:id" element={<MilestoneTemplateView />} />
              <Route path="/milestones/bundles" element={<MilestoneBundles />} />
              <Route path="/milestones/bundles/new" element={<NewMilestoneBundle />} />
              <Route path="/milestones/bundles/:id" element={<MilestoneBundleView />} />
            </Routes>
          </Box>
        </Box>
      </ThemeProvider>
    
  );
}
const components = {
  SignIn: {
    Header() {
      const { tokens } = useTheme();
      console.log("SIGN IN TOKENS ", tokens)
      return (
        <View textAlign="center" padding={tokens.space.large}>
          <View display="flex" flex="row" alignSelf="center" >
            <View display="flex">
              <img src={Logo} height={60} alt="" />
            </View>
            <Box style={{ display: "flex", flexDirection: "column", marginLeft: 10, alignItems: "flex-start" }} >
              <div style={{ lineHeight: 1, color: '#2D3142', fontSize: 50, fontWeight: 'bold', textTransform: 'uppercase' }}>
                CRISP
              </div>
              <div style={{ lineHeight: 1, color: '#2D3142', fontSize: 14, textTransform: 'uppercase' }}>
                by Criterium Engineers
              </div>
            </Box>
          </View>
        </View>
      );
    }
  }
}

export default withAuthenticator(App, { hideSignUp: true, components: components });

export async function getStaticProps() {
  return {
    props: {
      isPassedToWithAuthenticator: true,
    },
  };
}
