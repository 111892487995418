import React, { useCallback, useEffect, useState } from 'react';
import GQLClient from '../GQLClient';

import { listHierarchyItems } from '../graphql/queries';
import { UpdateHierarchyItemInput } from '../API';
import LoadingIndicator from '../common/LoadingIndicator';
import CheckboxTree from 'react-checkbox-tree';

function treeify(list: any[], idAttr: string, parentAttr: string, childrenAttr: string) {
  var treeList: any[] = []
  var lookup: any = {}
  list.forEach(function (obj) {
    lookup[obj[idAttr]] = obj
    //obj[childrenAttr] = []
  });
  list.forEach(function (obj) {
    if (obj[parentAttr] != null) {
      if (lookup[obj[parentAttr]] !== undefined) {
        if (!lookup[obj[parentAttr]][childrenAttr]) {
          lookup[obj[parentAttr]][childrenAttr] = [obj]
        } else {
          lookup[obj[parentAttr]][childrenAttr].push(obj)
        }
      } else {
        treeList.push(obj)
      }
    } else {
      treeList.push(obj)
    }
  });
  return treeList
};

type ChangeCallback = (value: any[]) => any;

export const HierarchyCheckboxTree: React.FC<{ selected: string[], onChange: ChangeCallback, mode: 'include' | 'exclude', disabled?: boolean }> = ({ selected, onChange, mode, disabled = false }) => {
  const [nodes, setNodes] = useState<any[]>([])
  const [checked, setChecked] = useState<any[]>([])
  const [expanded, setExpanded] = useState<any[]>([])

  useEffect(() => {
    setChecked(selected)
  }, [selected])

  const onCheck = (value: any[]) => {
    setChecked(value)
    onChange(value)
  }

  const fetchHierarchy = useCallback(async () => {
    let items: any[] = []

    let nextToken;
    while (nextToken || !items.length) {
      let hierarchyItemsData = await GQLClient.graphql({
        query: listHierarchyItems, variables: {
          limit: 1000,
          nextToken: nextToken
        }
      }) as any
      nextToken = hierarchyItemsData.data.listHierarchyItems.nextToken
      let hierarchyItems = hierarchyItemsData.data.listHierarchyItems.items
        .filter((i: any) => !i._deleted)
        .map((item: UpdateHierarchyItemInput) => {
          return {
            label: `${item.code} - ${item.name}`,
            value: item.id,
            parentId: item.parentId
          }
        })

      items = items.concat(hierarchyItems)
    }

    let itemTree = treeify(items.sort((a: any, b: any) => a.label > b.label ? 1 : -1), "value", "parentId", "children")
    setNodes(itemTree)
  }, [])

  useEffect(() => {
    fetchHierarchy()
  }, [fetchHierarchy])

  if (!nodes.length) {
    return <LoadingIndicator />
  }

  return (
    <CheckboxTree
      disabled={disabled}
      nodes={nodes}
      checked={checked}
      expanded={expanded}
      onCheck={onCheck}
      onExpand={setExpanded}
      checkModel="all"
      expandOnClick={true}
      onClick={() => null}
      optimisticToggle={false}
      iconsClass="fa5"
      icons={{
        leaf: <></>,
        check: mode === 'include'
          ? <span className="rct-icon rct-icon-check" />
          : <span className="rct-icon rct-icon-uncheck" />,
        uncheck: mode === 'include'
          ? <span className="rct-icon rct-icon-uncheck" />
          : <span className="rct-icon rct-icon-check" />,
      }}
    />
  )
}