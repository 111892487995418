import GQLClient from "../GQLClient";
import { useCallback, useEffect, useState } from "react";
import { UpdateMilestoneTemplateInput } from "../API";
import { listMilestoneTemplates } from "../graphql/queries";
import { MilestoneTemplateStatus } from "../models";

export function useActiveMilestoneTemplates(currentTemplateId?: string | null) {
  const [templates, setTemplates] = useState<UpdateMilestoneTemplateInput[]>([])

  const fetchTemplates = useCallback(async () => {
    try {
      const templatesData = await GQLClient.graphql({ query: listMilestoneTemplates }) as any
      const templates = templatesData.data.listMilestoneTemplates.items
      setTemplates(templates.filter((t: UpdateMilestoneTemplateInput) =>
        t.status === MilestoneTemplateStatus.ACTIVE || (currentTemplateId && currentTemplateId === t.id))
      )
    } catch (err) { console.log('error fetching milestone templates') }
  }, [currentTemplateId])

  useEffect(() => {
    fetchTemplates()
  }, [fetchTemplates])

  return templates
}