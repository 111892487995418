import React, { useCallback, useEffect, useState } from 'react';
import { Button, Grid, TextField } from '@mui/material';

import { useParams } from 'react-router-dom';
import { getClient } from '../graphql/queries';
import { UpdateClientInput } from '../API';
import { updateClient } from '../graphql/mutations';
import LoadingIndicator from '../common/LoadingIndicator';
import GQLClient from '../GQLClient';

export default function ClientReportSettings() {
  const { id } = useParams() as any

  const [client, setClient] = useState<UpdateClientInput>()
  const [changes, setChanges] = useState<any>(undefined)

  const fetchClient = useCallback(async () => {
    const clientData = await GQLClient.graphql({ query: getClient, variables: { id: id } }) as any
    setClient(clientData.data.getClient)
  }, [id])

  const setClientValue = (event: any) => {
    setClient({
      ...client,
      [event.target.name]: event.target.value
    } as UpdateClientInput)

    setChanges({
      ...changes,
      [event.target.name]: event.target.value
    })
  }

  const saveChanges = async () => {
    const clientData = await GQLClient.graphql({
      query: updateClient, variables: {
        input: {
          id: client!.id,
          _version: client!._version,
          ...changes
        }
      }
    }) as any

    setClient(clientData.data.updateClient)
    setChanges(undefined)
  }

  useEffect(() => {
    fetchClient()
  }, [fetchClient])

  if (!client) {
    return <LoadingIndicator />
  }

  return (
    <Grid container spacing={3} alignItems="flex-start">
      <Grid container spacing={3} item xs={12}>
        <Grid item xs={12}>
          <TextField
            name="reportDisclaimer"
            label="Disclaimer"
            fullWidth
            variant="outlined"
            multiline
            rows={20}
            maxRows={20}
            value={client.reportDisclaimer || ''}
            onChange={setClientValue}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={saveChanges}
            disabled={!changes}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}