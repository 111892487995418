import React, { useCallback, useEffect, useState } from 'react';
import GQLClient from '../GQLClient';
import { Card, CardContent, Grid } from '@mui/material';

import { useParams } from 'react-router-dom';
import { getProject } from '../graphql/queries';
import { UpdateProjectInput } from '../API';
import { updateProject } from '../graphql/mutations';
import LoadingIndicator from '../common/LoadingIndicator';
import { HierarchyCheckboxTree } from '../common/HierarchyCheckboxTree';
import { LoadingButton } from '../common/LoadingButton';

export default function ProjectHierarchyScope() {
  const { id } = useParams() as any

  const [project, setProject] = useState<UpdateProjectInput>()
  const [changes, setChanges] = useState<any>(undefined)
  const [exclusions, setExclusions] = useState<any[]>([])

  const fetchProject = useCallback(async () => {
    const projectData = await GQLClient.graphql({ query: getProject, variables: { id: id } }) as any
    setProject(projectData.data.getProject)
    setExclusions(projectData.data.getProject.exclusions
      ? JSON.parse(projectData.data.getProject.exclusions)
      : []
    )
  }, [id])

  useEffect(() => {
    fetchProject()
  }, [fetchProject])

  const onExclusionsChange = (value: any) => {
    setExclusions(value)
    setChanges({
      ...changes,
      exclusions: JSON.stringify(value)
    })
  }

  const saveChanges = async () => {
    const projectData = await GQLClient.graphql({
      query: updateProject, variables: {
        input: {
          id: project!.id,
          _version: project!._version,
          ...changes
        }
      }
    }) as any

    let updatedProject = projectData.data.updateProject
    setProject(updatedProject)
    setExclusions(updatedProject?.exclusions
      ? JSON.parse(updatedProject.exclusions)
      : [])
    setChanges(undefined)
  }

  if (!project) {
    return <LoadingIndicator />
  }

  return (
    <Grid container spacing={3} alignItems="flex-start">
      <Grid item xs={12}>
        <Card variant="outlined">
          <CardContent>
            <HierarchyCheckboxTree
              onChange={onExclusionsChange}
              mode="exclude"
              selected={exclusions}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <LoadingButton
          variant="contained"
          color="primary"
          disabled={!changes}
          onClick={saveChanges}
          style={{ fontWeight: 'bold', textTransform: 'uppercase' }}
        >
          Save
        </LoadingButton>
      </Grid>
    </Grid>
  )
}