import React from 'react';
import { Button, Grid, Paper } from '@mui/material';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { UpdatePhaseInput } from '../API';
import { PhasesGrid } from '../phase/PhasesGrid';

export const ProjectPhases = () => {
  const { id } = useParams() as any;

  const navigate = useNavigate();
  function onSelect(item: UpdatePhaseInput) {
    navigate(`/phases/${item.id}`)
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} style={{ textAlign: 'right' }}>
        <Link to={`/projects/${id}/phase`} style={{ textDecoration: 'none' }}>
          <Button variant="contained" color="primary">
            Add New
          </Button>
        </Link>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <PhasesGrid
            id="projectPhases"
            projectId={id}
            onSelect={onSelect}
            columnDefs={[
              {
                field: "name",
                headerName: "Name",
                sortable: true,
                filter: true
              },
              {
                field: "status",
                headerName: "Status",
                sortable: true,
                filter: true
              }
            ]}>
          </PhasesGrid>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default ProjectPhases
