import { CircularProgress, TextField } from '@mui/material';
import { Autocomplete } from '@mui/lab';
import { IFilterParams } from 'ag-grid-community';
import GQLClient from '../GQLClient';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { listProjects } from '../graphql/queries';

export const ProjectFilter = forwardRef((props: IFilterParams, ref: any) => {
  const [value, setValue] = useState<any | null>(null)
  const [inputValue, setInputValue] = useState<string>('')
  const [options, setOptions] = useState<any>([])
  const [open, setOpen] = React.useState(false);
  const loading = open && options.length === 0;

  const { filterChangedCallback } = props
  let filterValue = useRef()

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      try {
        const projectData = await GQLClient.graphql({ query: listProjects }) as any
        const projects = projectData.data.listProjects.items
        if (active) {
          setOptions(projects)
        }
      } catch (err) { console.log('error fetching projects') }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  useEffect(() => {
    const previousFilterValue = filterValue.current;
    filterValue.current = value ? value.id : null

    //on initial value set we don't need callback
    if (previousFilterValue !== undefined && previousFilterValue !== filterValue.current) {
      filterChangedCallback()
    }
  }, [filterChangedCallback, value]);

  useImperativeHandle(ref, () => {
    return {
      isFilterActive() {
        return value != null && value !== '';
      },

      getModel() {
        if (!value) return null

        return {
          type: 'equals',
          filter: filterValue.current
        };
      },

      setModel(model: any) {
        setValue(model ? model.filter : null)
      }
    };
  });

  return (
    <div className="ag-filter">
      <div className="ag-filter-wrapper ag-focus-managed">
        <div className="ag-filter-body-wrapper ag-simple-filter-body-wrapper">
          <Autocomplete
            className="ag-custom-component-popup"
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            autoComplete={true}
            autoSelect={true}
            value={value}
            onChange={(event: any, newValue: any | null) => setValue(newValue)}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
            options={options}
            loading={loading}
            isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
            getOptionLabel={(option: any) => {
              return option.name ? option.name : ""
            }}
            style={{ width: 300 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Project Name"
                size="small"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        </div>
      </div>
    </div>
  )
})