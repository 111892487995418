import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Box, Button, ButtonGroup } from '@mui/material';

import GQLClient from '../GQLClient';
import { findingsByProjectId } from '../graphql/custom_queries';
import LoadingIndicator from '../common/LoadingIndicator';
import { UpdateObservationInput } from '../API';
import { HierarchyItemFinding } from './HierarchyItemFinding';
import { useNavigate } from 'react-router-dom';
import { FindingStatus } from '../models';
import { InspectionContext } from './InspectionContext';

type Props = {
};

export const PriorItems: React.FC<Props> = (props) => {
  const { inspection, editable } = useContext(InspectionContext);

  const navigate = useNavigate()

  const [findings, setFindings] = useState<any[]>()
  const [statusFilter, setStatusFilter] = useState<FindingStatus | null | undefined>()
  const [hierarchyFindings, setHierarchyFindings] = useState<any[]>([])

  const fetchFindings = useCallback(async () => {
    if (inspection?.projectId) {
      let results: any
      if (inspection.phaseId) {
        results = await GQLClient.graphql({ query: findingsByProjectId, variables: { projectId: inspection.projectId, filter: { phaseId: { eq: inspection.phaseId } }, limit: 1000 } }) as any
      } else {
        results = await GQLClient.graphql({ query: findingsByProjectId, variables: { projectId: inspection.projectId, limit: 1000 } }) as any
      }
      const findingsData = results.data.findingsByProjectId.items
        .filter((o: any) => !o._deleted) as any[]
      setFindings(findingsData)
    } else {
      setFindings([])
    }
  }, [inspection])

  useEffect(() => {
    fetchFindings()
  }, [fetchFindings])

  const processFindings = useCallback(async () => {
    const hierarchyFindings: any[] = []

    for (const f of findings ? findings : []) {
      if (statusFilter === undefined || f.status === statusFilter) {
        const existing = hierarchyFindings.find(
          (h) => h.id === f.hierarchyItem!.id,
        )
        if (!existing) {
          hierarchyFindings.push({
            id: f.hierarchyItem!.id,
            hierarchyItem: f.hierarchyItem,
            findings: [f],
          })
        } else {
          existing.findings.push(f)
        }
      }
    }

    setHierarchyFindings(
      hierarchyFindings.sort((a, b) =>
        (a.hierarchyItem?.code || '') > (b.hierarchyItem?.code || '')
          ? 1
          : -1,
      ),
    )

  }, [findings, statusFilter])

  useEffect(() => {
    processFindings()
  }, [processFindings])

  if (!findings) return <LoadingIndicator />

  return (
    <Box display="flex" flexDirection="column">
      <ButtonGroup size="medium" style={{ marginBottom: 20 }}>
        <Button
          variant={statusFilter === undefined ? 'contained' : 'outlined'}
          onClick={() => setStatusFilter(undefined)}
          color="primary"
          style={{ flex: 1 }}
        >
          All
        </Button>
        <Button
          variant={statusFilter === FindingStatus.OPEN ? 'contained' : 'outlined'}
          onClick={() => setStatusFilter(FindingStatus.OPEN)}
          color="primary" style={{ flex: 1 }}
        >
          Open
        </Button>
        <Button
          variant={statusFilter === FindingStatus.CLOSED ? 'contained' : 'outlined'}
          onClick={() => setStatusFilter(FindingStatus.CLOSED)}
          color="primary" style={{ flex: 1 }}
        >
          Closed
        </Button>
        <Button
          variant={statusFilter === FindingStatus.NEW ? 'contained' : 'outlined'}
          onClick={() => setStatusFilter(FindingStatus.NEW)}
          color="primary" style={{ flex: 1 }}
        >
          NEW
        </Button>
      </ButtonGroup>
      {hierarchyFindings?.map((h) =>
        <HierarchyItemFinding
          key={h.id}
          item={h}
          editable={editable}
          onClick={(item: UpdateObservationInput) => {
            navigate(`../observations/${item.id}`)
          }}
        />
      )}
    </Box>
  );
}
