import { useEffect, useState } from "react";
import { getCurrentUser } from "aws-amplify/auth"

export function useCurrentUser() {
  const [username, setUsername] = useState<string | undefined>('');


  useEffect(() => {
    let isMounted = true
    let currentUsername: string | undefined



    getCurrentUser()
      .then(userInfo => currentUsername = userInfo.username)
      .catch(() => {
        currentUsername = undefined
      })
      .finally(() => {
        if (isMounted) {
          setUsername(currentUsername)
        }
      })

    return () => { isMounted = false }
  }, [])

  return username;
}