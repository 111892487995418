import React, { useEffect, useState } from 'react';
import GQLClient from '../GQLClient';
import { createHierarchyItem } from '../graphql/mutations';
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, Button, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Theme, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import { CreateHierarchyItemInput, UpdateHierarchyItemInput } from '../API';
import { Add, ExpandMore } from '@mui/icons-material';
import { HierarchyItemInspectionPointView } from './HierarchyItemInspectionPointView';
import { hierarchyItemsByParentId } from '../graphql/queries';


const MAX_LEVEL = 4

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootHeader: {
    },
    expandedHeader: {
      backgroundColor: '#c0c0c0'
    }
  })
);

export const HierarchyLineItem: React.FC<{
  item: UpdateHierarchyItemInput,
  onChange?: Function,
  onHide: Function,
  hiddenChange: boolean,
  expanded?: string | false,
  level?: number,
  hierarchyItems?: any
}> = ({ item, onChange, onHide, hiddenChange, expanded, level = 1, hierarchyItems }) => {
  // const classes = useStyles()

  const [subExpanded, setSubExpanded] = useState<string | false>(false);
  const [addDialogOpen, setAddDialogOpen] = useState<boolean>(false)
  const [name, setName] = useState<string>('')
  const [code, setCode] = useState<string>('')
  const [subItems, setSubItems] = useState<UpdateHierarchyItemInput[]>([])

  async function addHierarchyItem() {
    try {
      if (!name) return
      const hierarchyItemData = await GQLClient.graphql({
        query: createHierarchyItem, variables: {
          input: {
            name: name,
            code: code,
            hierarchyId: item.hierarchyId,
            parentId: item.id,
          } as CreateHierarchyItemInput
        }
      }) as any
      const subItem = hierarchyItemData.data.createHierarchyItem

      setSubItems([
        ...subItems,
        subItem
      ])
      setName('')
      setCode('')
      setAddDialogOpen(false)
    } catch (err) {
      console.log('error creating hierarchy item:', err)
    }
  }

  useEffect(() => {
    const loadChildren = async () => {
      const hierarchyItemsData = await GQLClient.graphql({
        query: hierarchyItemsByParentId, variables: {
          parentId: item.id
        }
      }) as any

      const hierarchyItems = hierarchyItemsData.data.hierarchyItemsByParentId.items
        .filter((i: any) => !i._deleted)
        .sort((a: any, b: any) => a.code > b.code ? 1 : -1)

      setSubItems(hierarchyItems)
    }

    if (item.id === expanded || hiddenChange) {
      loadChildren()
    }

  }, [item.id, expanded, subExpanded, hiddenChange])

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    onChange && onChange(panel, isExpanded)
  };

  const handleSubChange = (panel: string, isExpanded: boolean) => {
    setSubExpanded(isExpanded ? panel : false);
  };

  const handleHide = async (event: any) => {
    event.stopPropagation()
    onHide(item, event.target.checked)
  }

  if (subExpanded) {
    const item = subItems.find(i => i.id === subExpanded)
    return (
      <HierarchyLineItem
        level={level + 1}
        onChange={handleSubChange}
        onHide={onHide}
        hiddenChange={hiddenChange}
        expanded={subExpanded}
        key={item!.id}
        item={item!}
        hierarchyItems={hierarchyItems}
      />
    )
  }

  return (
    <div>
      <Accordion expanded={expanded === item.id} onChange={handleChange(item.id!)} style={{ marginBottom: 10 }}>
        <AccordionSummary expandIcon={<ExpandMore />} >
          <Grid container >
            <Grid item xs={12} md={6}>
              {item.code && <span style={{ marginRight: 10, fontWeight: 'bold' }}>{item.code}</span>}
              {item.name}
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControlLabel
                aria-label="Hidden"
                onClick={(event) => handleHide(event)}
                onFocus={(event) => event.stopPropagation()}
                control={<Checkbox id={item.id} checked={item!.hidden ? item.hidden : false}  sx={{
                  '&.Mui-checked': {
                    color: "#f50057",
                  },
                }} />}
                label={<Typography variant="body2">Hidden</Typography>}
              />
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ marginBottom: 10, width: '100%' }}>
            {(expanded === item.id && level < MAX_LEVEL) &&
              <div>
                {subItems.map(i => (
                  <HierarchyLineItem
                    level={level + 1}
                    onChange={handleSubChange}
                    onHide={onHide}
                    hiddenChange={hiddenChange}
                    expanded={subExpanded}
                    key={i.id}
                    item={i}
                    hierarchyItems={hierarchyItems}
                  />
                ))}

                {false && level < MAX_LEVEL &&
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Add />}
                    onClick={() => setAddDialogOpen(true)}
                  >
                    Add Hierarchy Item
                  </Button>
                }
              </div>
            }

            {expanded === item.id &&
              <HierarchyItemInspectionPointView hierarchyItemId={item.id} />
            }
          </div>
        </AccordionDetails>
      </Accordion>
      <Dialog fullWidth={true} maxWidth="sm" open={addDialogOpen} onClose={() => setAddDialogOpen(false)}>
        <DialogTitle>Add Hierarchy Item</DialogTitle>
        <DialogContent>
          <TextField
            style={{ marginBottom: 10 }}
            label="Hierarchy Item Name"
            variant="outlined"
            size="small"
            fullWidth
            onChange={(event: any) => setName(event.target.value)}
            value={name}
          />
          <TextField
            label="Hierarchy Item Code"
            variant="outlined"
            size="small"
            fullWidth
            onChange={(event: any) => setCode(event.target.value)}
            value={code}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setAddDialogOpen(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={addHierarchyItem}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}