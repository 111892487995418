import React, { useCallback, useEffect, useState } from 'react';
import GQLClient from '../GQLClient';
import { Grid, Tab, Tabs, Typography } from '@mui/material';

import { useParams } from 'react-router-dom';
import { getProject } from '../graphql/queries';
import ProjectDetails from './ProjectDetails';
import { UpdateProjectInput } from '../API';
import ProjectInspections from './ProjectInspections';
import ProjectReportSettings from './ProjectReportSettings';
import ProjectDistributionList from './ProjectDistributionList';
import { ProjectPhases } from './ProjectPhases';
import LoadingIndicator from '../common/LoadingIndicator';
import ProjectNotes from './ProjectNotes';
import ProjectHierarchyScope from './ProjectHierarchyScope';

export default function ProjectView() {
  const { id } = useParams() as any;

  const [project, setProject] = useState<UpdateProjectInput>()
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  const fetchProject = useCallback(async () => {
    const clientData = await GQLClient.graphql({ query: getProject, variables: { id: id } }) as any
    setProject(clientData.data.getProject)
  }, [id])

  useEffect(() => {
    fetchProject()
  }, [fetchProject])

  if (!project) {
    return <LoadingIndicator />
  }

  return (
    <Grid container spacing={3} sx={{ height: '100%' }}>
      <Grid item xs={12}>
        <Typography variant="h5" style={{ fontWeight: 'bold' }}>
          {project.name}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Tabs value={selectedTab} onChange={handleTabChange} >
          <Tab label="Details" />
          <Tab label="Inspections" />
          <Tab label="Distribution" />
          <Tab label="Report Settings" />
          <Tab label="Hierarchy Scope" />
          <Tab label="Phases" />
          <Tab label="Notes" />
        </Tabs>
      </Grid>
      <Grid item xs={12}>
        {selectedTab === 0 &&
          <ProjectDetails />
        }
        {selectedTab === 1 &&
          <ProjectInspections />
        }
        {selectedTab === 2 &&
          <ProjectDistributionList project={project} fetchProject={fetchProject} />
        }
        {selectedTab === 3 &&
          <ProjectReportSettings />
        }
        {selectedTab === 4 &&
          <ProjectHierarchyScope />
        }
        {selectedTab === 5 &&
          <ProjectPhases />
        }
        {selectedTab === 6 &&
          <ProjectNotes />
        }
      </Grid>
    </Grid>
  );
}