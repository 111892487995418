import React from "react"
import { Avatar, IconButton, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Menu, MenuItem } from "@mui/material"
import { MoreHorizOutlined } from "@mui/icons-material"
import { bindMenu, bindTrigger } from "material-ui-popup-state"
import { usePopupState } from "material-ui-popup-state/hooks"
import { UpdateUserInput } from "../API"

export const ProjectUser: React.FC<{ item: UpdateUserInput, onChange: Function, onDelete: Function }> = ({ item, onChange, onDelete }) => {
    const popupState = usePopupState({ variant: 'popover', popupId: 'contactActionPopup' })

    const _onChange = () => {
        onChange(item)
        popupState.close()
    }

    const _onDelete = () => {
        onDelete(item)
        popupState.close()
    }

    return (
        <ListItem key={item.id} alignItems="flex-start" style={{ alignItems: 'center' }}>
            <ListItemAvatar>
                <Avatar>
                    {`${item.firstName ? item.firstName[0] : ''}${item.lastName ? item.lastName[0] : ''}`}
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                style={{ flex: 1 }}
                primary={`${item.firstName} ${item.lastName}`}
                secondary={item.companyName}
            />
            <ListItemSecondaryAction>
                <IconButton edge="end" {...bindTrigger(popupState)}>
                    <MoreHorizOutlined />
                </IconButton>
                <Menu {...bindMenu(popupState)}>
                    <MenuItem onClick={() => { _onChange() }}>Change</MenuItem>
                    <MenuItem onClick={() => { _onDelete() }}>Remove</MenuItem>
                </Menu>
            </ListItemSecondaryAction>
        </ListItem>
    )
}