import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Box, Button, ButtonGroup } from '@mui/material';

import GQLClient from '../GQLClient';
import { inspectionHierarchyItemsByInspectionId, observationsByInspectionId } from '../graphql/queries';

import LoadingIndicator from '../common/LoadingIndicator';
import {
  UpdateInspectionHierarchyItemInput,
  UpdateObservationInput,
}
  from '../API';
import { HierarchyItemObservation, HierarchyItemObservations } from './HierarchyItemObservation';
import { useNavigate, useResolvedPath } from 'react-router-dom';
import { InspectionStatus, ObservationStatus } from '../models';
import { InspectionContext } from './InspectionContext';
import { NewObservation } from './NewObservation';


export const InspectionObservations: React.FC = () => {
  const { inspection, editable } = useContext(InspectionContext);
  const [inspectionId, setInspectionId] = useState<string>()
  useEffect(() => {
    setInspectionId(inspection?.id)
  }, [inspection])

  const navigate = useNavigate()
  const currentPath = useResolvedPath('')

  const [observations, setObservations] = useState<any[]>()
  const fetchObservations = useCallback(async () => {
    if (inspectionId) {
      const observationsData = await GQLClient.graphql({ query: observationsByInspectionId, variables: { inspectionId: inspectionId } }) as any
      const observations = observationsData.data.observationsByInspectionId.items
        .filter((o: any) => !o._deleted) as any[]
      setObservations(observations)
    } else {
      setObservations([])
    }
  }, [inspectionId])

  useEffect(() => {
    fetchObservations()
  }, [fetchObservations])

  const [inspectionHierarchyItems, setInspectionHierarchyItems] = useState<any[]>([])
  const fetchInspectionHierarchyItems = useCallback(async () => {
    if (inspectionId) {
      const inspectionHierarchyItemsData = await GQLClient.graphql({ query: inspectionHierarchyItemsByInspectionId, variables: { inspectionId: inspectionId } }) as any
      const inspectionHierarchyItems = inspectionHierarchyItemsData.data.inspectionHierarchyItemsByInspectionId.items
        .filter((o: any) => !o._deleted) as any[]
      setInspectionHierarchyItems(inspectionHierarchyItems)
    } else {
      setInspectionHierarchyItems([])
    }
  }, [inspectionId])

  useEffect(() => {
    fetchInspectionHierarchyItems()
  }, [fetchInspectionHierarchyItems])

  const [observedHierarchyItems, setobservedHierarchyItems] = useState<UpdateInspectionHierarchyItemInput[]>([])
  useEffect(() => {
    setobservedHierarchyItems(inspectionHierarchyItems
      .filter(i => i.notes || i.photos?.length)
    )
  }, [inspectionHierarchyItems])


  const [statusFilter, setStatusFilter] = useState<ObservationStatus | null | undefined>()
  const [hierarchyObservations, setHierarchyObservations] = useState<HierarchyItemObservations[]>([])
  const processObservations = useCallback(async () => {
    const hierarchyObservations: HierarchyItemObservations[] = []
    for (const i of observedHierarchyItems) {
      const inspectionHierarchyItem = i as any
      hierarchyObservations.push({
        id: inspectionHierarchyItem.hierarchyItemId!,
        inspectionHierarchyItem: i,
        hierarchyItem: inspectionHierarchyItem.hierarchyItem,
        observations: []
      })
    }
    for (const o of observations || []) {
      if (statusFilter === undefined || o.status === statusFilter) {
        const existing = hierarchyObservations.find(h => h.id === o.hierarchyItemId)

        if (!existing) {
          const inspectionHierarchyItem = inspectionHierarchyItems.find(i => i.hierarchyItem!.id === o.hierarchyItemId)
          hierarchyObservations.push({
            id: o.hierarchyItemId,
            inspectionHierarchyItem: inspectionHierarchyItem,
            hierarchyItem: o.hierarchyItem,
            observations: [o]
          })
        } else {
          existing.observations.push(o)
        }
      }
    }
    setHierarchyObservations(hierarchyObservations
      .sort((a, b) => (a.hierarchyItem?.code || '') > (b.hierarchyItem?.code || '') ? 1 : -1)
    )
  }, [observations, observedHierarchyItems, inspectionHierarchyItems, statusFilter])

  useEffect(() => {
    processObservations()
  }, [processObservations])

  const [createNewObservation, setCreateNewObservation] = useState<boolean>(false)
  const handleCreateObservation = () => {
    setCreateNewObservation(true)
  }

  const handleClose = () => {
    setCreateNewObservation(false)
  }

  if (!observations) return <LoadingIndicator />

  return (
    !createNewObservation ?
      <div>
        < Box display="flex" flexDirection="column" >
          <ButtonGroup size="medium" style={{ marginBottom: 20 }}>
            <Button
              variant={statusFilter === undefined ? 'contained' : 'outlined'}
              onClick={() => setStatusFilter(undefined)}
              color="primary"
              style={{ flex: 1 }}
            >
              All
            </Button>
            <Button
              variant={statusFilter === ObservationStatus.NEW ? 'contained' : 'outlined'}
              onClick={() => setStatusFilter(ObservationStatus.NEW)}
              color="primary" style={{ flex: 1 }}
            >
              Pending
            </Button>
            <Button
              variant={statusFilter === ObservationStatus.ACCEPTED ? 'contained' : 'outlined'}
              onClick={() => setStatusFilter(ObservationStatus.ACCEPTED)}
              color="primary"
              style={{ flex: 1 }}
            >
              Accepted
            </Button>
            <Button
              variant={statusFilter === ObservationStatus.DECLINED ? 'contained' : 'outlined'}
              onClick={() => setStatusFilter(ObservationStatus.DECLINED)}
              color="primary"
              style={{ flex: 1 }}
            >
              Declined
            </Button>
          </ButtonGroup>
          <Box component="span" m={1}>
            <Button
              onClick={handleCreateObservation}
              disabled={!(inspection!.status === InspectionStatus.REVIEWING || inspection!.status === InspectionStatus.REVISING || inspection!.status === InspectionStatus.ACTIVEW)}
              variant="contained"
              color="primary"
            >
              Create New Observation
            </Button>
          </Box>

          {
            hierarchyObservations?.map((h) =>
              <HierarchyItemObservation
                key={h.id}
                item={h}
                editable={editable}
                onClick={(item: UpdateObservationInput) => {
                  navigate(`${currentPath.pathname}/${item.id}`)
                }}
              />
            )
          }
        </Box >
      </div > :
      <NewObservation handleClose={handleClose} />


  );
}
