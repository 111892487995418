import React, { useEffect, useState } from 'react';
import GQLClient from '../GQLClient';
import { createUser } from '../graphql/mutations';
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';

import { useNavigate } from 'react-router-dom';
import { CreateContactInput, CreateUserInput, UpdateContactInput } from '../API';
import { ContactEditor } from '../contact/ContactEditor';
import { UserStatus, UserType } from '../models';
import { LoadingButton } from '../common/LoadingButton';
import { post } from 'aws-amplify/api';

const initialState = {
  firstName: '',
  lastName: '',
  companyName: '',
  jobTitle: '',
  email: '',
  phone: '',
} as CreateContactInput

export const NewUser = () => {
  const [contact, setContact] = useState<CreateContactInput | UpdateContactInput>(initialState)
  const [username, setUsername] = useState<string>('')
  const [userType, setUserType] = useState<UserType | ''>('')
  const navigate = useNavigate();
  useEffect(() => {
  }, [])

  async function addUser() {
    try {
      if (username === '' || userType === '' || !contact.firstName || !contact.email || !contact.phone) return



      post({ apiName: "cognito", path: "/user", options: { body: { username: username, email: contact.email, phone: contact.phone } } })

      const userData = await GQLClient.graphql({
        query: createUser, variables: {
          input: {
            id: username,
            status: UserStatus.ACTIVE,
            type: userType,
            ...contact
          } as CreateUserInput
        }
      }) as any
      const user = userData.data.createUser

      navigate(`/team/${user.id}`)
    } catch (err) {
      console.log('error creating user:', err)
    }
  }

  function valid() {
    return username
      && userType
      && contact.firstName
      && contact.lastName
      && contact.companyName
      && contact.email
      && contact.phone
      && contact.phone.replace(/\D/g, "").length === 10;
  }

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" style={{ fontWeight: 'bold' }}>
            Add User
          </Typography>
        </Grid>
        <Grid container spacing={3} item xs={6}>
          <Grid item xs={12}>
            <TextField
              label="Username"
              variant="outlined"
              size="small"
              fullWidth
              onChange={(event: any) => setUsername(event.target.value.toLowerCase())}
              value={username}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl size="small" fullWidth variant="outlined">
              <InputLabel id="user-type-label">User Type</InputLabel>
              <Select
                labelId="user-type-label"
                value={userType}
                onChange={(event: any) => setUserType(event.target.value)}
                label="User Type"
              >
                <MenuItem value={''}></MenuItem>
                <MenuItem value={UserType.ADMIN}>Admin</MenuItem>
                <MenuItem value={UserType.EFT}>EFT</MenuItem>
                <MenuItem value={UserType.REVIEWER}>Reviewer</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <ContactEditor contact={contact} editing={true} onChange={(contact: CreateContactInput) => { setContact(contact) }} />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            variant="contained"
            color="primary"
            disabled={!valid()}
            onClick={addUser}
            style={{ fontWeight: 'bold', textTransform: 'uppercase' }}
          >
            Add
          </LoadingButton>
        </Grid>
      </Grid>
    </div>
  );
}
