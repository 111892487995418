import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import GQLClient from '../GQLClient';
import { Button, Card, CardContent, CardHeader, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, IconButton, InputLabel, List, ListItem, ListItemSecondaryAction, ListItemText, MenuItem, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';

import { Link, useParams } from 'react-router-dom';
import { getPhase } from '../graphql/queries';
import { UnitInput, UpdatePhaseInput, UpdateProjectInput } from '../API';
import { PhaseStatus } from '../models';
import { updatePhase } from '../graphql/mutations';
import LoadingIndicator from '../common/LoadingIndicator';
import { LoadingButton } from '../common/LoadingButton';
import { Add, Delete, Edit } from '@mui/icons-material';

import { v4 as uuidv4 } from 'uuid';
import { useActiveMilestoneTemplates } from '../hooks/DataHooks';

export default function PhaseView() {
  const { id } = useParams() as any;

  const [phase, setPhase] = useState<UpdatePhaseInput>()
  const [units, setUnits] = useState<UnitInput[]>([])
  const [project, setProject] = useState<UpdateProjectInput>()
  const [changes, setChanges] = useState<any>()

  const fetchPhase = useCallback(async () => {
    const phaseData = await GQLClient.graphql({ query: getPhase, variables: { id: id } }) as any
    const phase = phaseData.data.getPhase
    setPhase(phase)
    setProject(phase.project)
  }, [id])

  useEffect(() => {
    fetchPhase()
  }, [fetchPhase])

  useEffect(() => {
    if (phase && phase.units) {
      let units: UnitInput[] = (phase.units ? phase.units.filter(p => p) : []) as UnitInput[]
      setUnits(units)
    } else {
      setUnits([])
    }
  }, [phase])

  function setPhaseValue(event: ChangeEvent<any> | SelectChangeEvent<string | null>) {
    setChanges({
      ...changes,
      [event.target.name]: event.target.value
    })

    setPhase({
      ...phase,
      [event.target.name]: event.target.value
    } as UpdatePhaseInput)
  }

  const savePhase = async () => {
    try {
      const phaseData = await GQLClient.graphql({
        query: updatePhase, variables: {
          input: {
            id: phase!.id,
            _version: phase!._version,
            ...changes
          }
        }
      }) as any

      setPhase(phaseData.data.updatePhase)
      setChanges(null)
    } catch (err) {
      console.log('error saving phase:', err)
    }
  }

  const [editingUnit, setEditingUnit] = useState<boolean>(false)
  const [deletingUnit, setDeletingUnit] = useState<boolean>(false)
  const [activeUnit, setActiveUnit] = useState<UnitInput>()

  const cancelEditingUnit = () => {
    setActiveUnit(undefined)
    setEditingUnit(false)
  }

  const addNewUnit = async () => {
    setActiveUnit({
      id: uuidv4(),
      name: ''
    })
    setEditingUnit(true)
  }

  const editUnit = async (unit: UnitInput) => {
    setActiveUnit(unit)
    setEditingUnit(true)
  }

  const setUnitValue = (key: string, value: any) => {
    setActiveUnit({
      ...activeUnit,
      [key]: value
    } as UnitInput)
  }

  const saveUnit = async () => {
    if (!activeUnit) return

    try {

      const updatedUnits = [...units]
      const idx = updatedUnits.findIndex(u => u.id === activeUnit.id)
      if (idx > -1) {
        updatedUnits[idx] = activeUnit
      } else {
        updatedUnits.push(activeUnit)
      }

      await saveUnits(updatedUnits)
      cancelEditingUnit()

    } catch (err) {
      console.log('error saving phase units:', err)
    }
  }

  const saveUnits = async (units: UnitInput[]) => {
    units = units.sort((a: any, b: any) => a.name > b.name ? 1 : -1)

    const phaseData = await GQLClient.graphql({
      query: updatePhase, variables: {
        input: {
          id: phase!.id,
          _version: phase!._version,
          units: units
        }
      }
    }) as any

    setPhase(phaseData.data.updatePhase)
  }

  const promptDeleteUnit = (unit: UnitInput) => {
    setActiveUnit(unit)
    setDeletingUnit(true)
  }

  const cancelDeletingUnit = () => {
    setActiveUnit(undefined)
    setDeletingUnit(false)
  }

  const deleteUnit = async () => {
    if (!activeUnit) return

    let unitIndex = units.indexOf(activeUnit)

    if (!unitIndex) return

    try {
      let updatedUnits = units
      updatedUnits.splice(unitIndex, 1)

      await saveUnits(updatedUnits)
      cancelDeletingUnit()

    } catch (err) {
      console.log('error deleting new phase unit:', err)
    }
  }

  const templates = useActiveMilestoneTemplates(phase?.milestoneTemplateId)

  if (!phase) {
    return <LoadingIndicator />
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" style={{ fontWeight: 'bold' }}>
            <Link to={`/projects/${project?.id}`} style={{ textDecoration: 'none' }}>
              {project?.name}
            </Link>
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <TextField
            label="Phase Name"
            variant="outlined"
            size="small"
            fullWidth
            name="name"
            onChange={setPhaseValue}
            value={phase.name}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <FormControl size="small" fullWidth variant="outlined">
            <InputLabel id="status-label">Status</InputLabel>
            <Select
              labelId="status-label"
              value={phase.status}
              name="status"
              onChange={setPhaseValue}
              label="User Type"
            >
              <MenuItem value={PhaseStatus.ACTIVE}>Active</MenuItem>
              <MenuItem value={PhaseStatus.INACTIVE}>Inactive</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid container item xs={4}>
          <FormControl size="small" fullWidth variant="outlined">
            <InputLabel id="milestone-label">Milestone Template</InputLabel>
            <Select
              labelId="milestone-label"
              value={changes?.milestoneTemplateId || phase.milestoneTemplateId || ''}
              name="milestoneTemplateId"
              onChange={setPhaseValue}
              label="MilestoneTemplate"
            >
              <MenuItem value="">None</MenuItem>
              {templates.map(template =>
                <MenuItem key={template.id} value={template.id}>{template.name}</MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Card variant="outlined">
            <CardHeader style={{ backgroundColor: 'silver' }}
              title='Units'
              titleTypographyProps={{ style: { fontWeight: 'bold', textTransform: 'uppercase', fontSize: 14 } }}
              action={
                <IconButton style={{ margin: 0 }} size="small" onClick={addNewUnit}>
                  <Add />
                </IconButton>
              }
            />
            <CardContent>
              {units.length > 0 &&
                <List>
                  <Divider />
                  {units.map(unit =>
                    <div key={unit.id}>
                      <ListItem>
                        <ListItemText
                          primary={unit.name}
                        />
                        <ListItemSecondaryAction>
                          <IconButton edge="end" aria-label="edit" onClick={() => editUnit(unit)}>
                            <Edit />
                          </IconButton>
                          <IconButton edge="end" aria-label="delete" onClick={() => promptDeleteUnit(unit)}>
                            <Delete />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                    </div>
                  )}
                </List>
              }
            </CardContent>
          </Card>
          <Dialog fullWidth={true} maxWidth="sm" open={editingUnit} onClose={cancelEditingUnit}>
            <DialogTitle>Unit Details</DialogTitle>
            <DialogContent>
              <TextField
                style={{ marginBottom: 10 }}
                label="Unit Name"
                variant="outlined"
                size="small"
                fullWidth
                name="name"
                inputProps={{ maxLength: 10 }}
                onChange={(event: any) => setUnitValue(event.target.name, event.target.value)}
                value={activeUnit?.name || ''}
              />
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" onClick={cancelEditingUnit} color="primary">
                Cancel
              </Button>
              <LoadingButton variant="contained" onClick={saveUnit} color="primary">
                Save
              </LoadingButton>
            </DialogActions>
          </Dialog>
          <Dialog
            onClose={(event, reason) => {
              if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                cancelDeletingUnit()
              }
            }}
            open={deletingUnit}
          >
            <DialogTitle style={{ width: 400 }}>Confirm</DialogTitle>
            <DialogContent>
              Delete {activeUnit?.name}?
            </DialogContent>
            <DialogActions>
              <Button onClick={cancelDeletingUnit} color="primary">
                Cancel
              </Button>
              <Button onClick={deleteUnit} color="primary">
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={savePhase}
            disabled={!changes}
            style={{ fontWeight: 'bold', textTransform: 'uppercase' }}
          >
            Save
          </LoadingButton>
        </Grid>
      </Grid>
    </>
  );
}