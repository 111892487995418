import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import GQLClient from '../GQLClient';
import { Card, CardContent, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';

import { useParams } from 'react-router-dom';
import { getHierarchyBundle } from '../graphql/queries';
import { UpdateHierarchyBundleInput } from '../API';
import { HierarchyBundleStatus } from '../models';
import { updateHierarchyBundle } from '../graphql/mutations';
import LoadingIndicator from '../common/LoadingIndicator';
import { LoadingButton } from '../common/LoadingButton';
import { HierarchyCheckboxTree } from '../common/HierarchyCheckboxTree';

export default function MilestoneBundleView() {
  const { id } = useParams() as any;

  const [bundle, setBundle] = useState<UpdateHierarchyBundleInput>()
  const [changes, setChanges] = useState<any>()

  const fetchBundle = useCallback(async () => {
    const bundleData = await GQLClient.graphql({ query: getHierarchyBundle, variables: { id: id } }) as any
    const bundle = bundleData.data.getHierarchyBundle
    setBundle(bundle)
  }, [id])

  useEffect(() => {
    fetchBundle()
  }, [fetchBundle])

  function setBundleValue(event: ChangeEvent<any> | SelectChangeEvent<string | null>) {
    setChanges({
      ...changes,
      [event.target.name]: event.target.value
    })

    setBundle({
      ...bundle,
      [event.target.name]: event.target.value
    } as UpdateHierarchyBundleInput)
  }

  const [hierarchyItemIds, setHierarchyItemIds] = useState<string[]>([])
  useEffect(() => {
    if (bundle?.hierarchyItemIds) {
      setHierarchyItemIds(JSON.parse(bundle.hierarchyItemIds))
    } else {
      setHierarchyItemIds([])
    }
  }, [bundle])

  function onHierarchyChange(value: string[]) {
    setChanges({
      ...changes,
      hierarchyItemIds: JSON.stringify(value)
    })
    setHierarchyItemIds(value)
  }

  const saveBundle = async () => {
    try {
      const bundleData = await GQLClient.graphql({
        query: updateHierarchyBundle, variables: {
          input: {
            id: bundle!.id,
            _version: bundle!._version,
            ...changes
          }
        }
      }) as any

      setBundle(bundleData.data.updateHierarchyBundle)
    } catch (err) {
      console.log('error saving bundle:', err)
    }
  }

  if (!bundle) {
    return <LoadingIndicator />
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5" style={{ fontWeight: 'bold' }}>
          Milestone Bundle
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <TextField
          label="Bundle Name"
          variant="outlined"
          size="small"
          fullWidth
          name="name"
          onChange={setBundleValue}
          value={bundle.name}
        />
      </Grid>
      <Grid item xs={4}>
        <FormControl size="small" fullWidth variant="outlined">
          <InputLabel id="status-label">Status</InputLabel>
          <Select
            labelId="status-label"
            value={bundle.status}
            name="status"
            onChange={setBundleValue}
            label="Status"
          >
            <MenuItem value={HierarchyBundleStatus.ACTIVE}>Active</MenuItem>
            <MenuItem value={HierarchyBundleStatus.INACTIVE}>Inactive</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Card variant="outlined">
          <CardContent>
            <HierarchyCheckboxTree
              onChange={onHierarchyChange}
              mode="include"
              selected={hierarchyItemIds}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={4}>
        <LoadingButton
          variant="contained"
          color="primary"
          onClick={saveBundle}
          disabled={!changes}
          style={{ fontWeight: 'bold', textTransform: 'uppercase' }}
        >
          Save
        </LoadingButton>
      </Grid>
    </Grid>
  );
}