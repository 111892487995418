import React from 'react';
import { Button, Grid, Paper, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { UpdateHierarchyBundleInput } from '../API';
import { IDatasource, IGetRowsParams } from 'ag-grid-community';
import GQLClient from '../GQLClient';
import { AgGridToElasticsearch } from '../common/AgGridHelpers';
import DataGrid from '../common/DataGrid';
import { useCurrentUser } from '../hooks/AuthHooks';

export const searchHierarchyBundles = /* GraphQL */ `
  query SearchHierarchyBundles(
    $filter: SearchableHierarchyBundleFilterInput
    $sort: [SearchableHierarchyBundleSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchHierarchyBundles(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        name
        type
        status
        hierarchyItemIds
        _version
        _deleted
        createdAt
        updatedAt
        owner
      }
      nextToken
      total
    }
  }
`;

export const MilestoneBundles = () => {
  const navigate = useNavigate();
  const username = useCurrentUser();

  function onRowSelected(item: UpdateHierarchyBundleInput) {
    navigate(`/milestones/bundles/${item.id}`)
  }

  const gridDataSource: IDatasource = {
    rowCount: undefined, // behave as infinite scroll
    getRows: async function (params: IGetRowsParams) {
      if(!username) return;
      const searchVariables = AgGridToElasticsearch(params.sortModel, params.filterModel)

      const bundlesData = await GQLClient.graphql({
        query: searchHierarchyBundles, variables: {
          ...searchVariables,
          from: params.startRow,
        }
      }) as any
      const bundles = bundlesData.data.searchHierarchyBundles.items
      const total = bundlesData.data.searchHierarchyBundles.total || 0

      params.successCallback(bundles, total);
    },
  }

  return (
    <Grid container spacing={3}>
      <Grid container item xs={12}>
        <Grid item xs={6}>
          <Typography variant="h5" style={{ fontWeight: 'bold' }}>
            Milestone Bundles
          </Typography>
        </Grid>
        <Grid item xs={6} style={{ textAlign: 'right' }}>
          <Link to="/milestones/bundles/new" style={{ textDecoration: 'none' }}>
            <Button variant="contained" color="primary">
              Add New
            </Button>
          </Link>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <DataGrid
            id="milestone-bundles"
            datasource={gridDataSource}
            onSelect={onRowSelected}
            columnDefs={[
              {
                field: "name",
                sortable: true,
                filter: "agTextColumnFilter",
                filterParams: { suppressAndOrCondition: true },
              },
              {
                field: "status",
                sortable: true,
                filter: "agTextColumnFilter",
                filterParams: { suppressAndOrCondition: true }
              }
            ]}>
          </DataGrid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default MilestoneBundles
