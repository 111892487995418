import React from 'react';
import { Grid, Paper } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { InspectionsGrid } from '../inspection/InspectionsGrid';
import { UpdateInspectionInput } from '../API';

export const ProjectInspections = () => {
  const { id } = useParams() as any;
  const navigate = useNavigate();
  function onSelect(item: UpdateInspectionInput) {
    navigate(`/inspection/${item.id}`)
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper>
          <InspectionsGrid
            id="projectInspections"
            filter={{ projectId: { eq: id } }}
            onSelect={onSelect}
            columnDefs={[
              {
                field: "phaseId",
                headerName: "Phase",
                sortable: true,
                cellRenderer: (params: any) => {
                  if (params.data && params.data.phase) {
                    return params.data.phase.name
                  } else {
                    return ''
                  }
                }
              },
              {
                field: "inspectionDate",
                headerName: "Inspection Date",
                sortable: true,
                filter: true,
                cellRenderer: (params: any) => {
                  if (params.data && params.data.inspectionDate) {
                    return new Date(params.data.inspectionDate).toLocaleDateString();
                  } else {
                    return ''
                  }
                }
              },
              {
                field: "number",
                headerName: "Number",
                cellRenderer: (params: any) => {
                  if (params.data && params.data.number) {
                    if (params.data.code === 'S') {
                      return params.data.number;
                    }
                    return `${params.data.code}-${params.data.number}`;
                  } else {
                    return ''
                  }
                }
              },
              {
                field: "reviewer",
                headerName: "Reviewing Engineer",
                cellRenderer: (params: any) => {
                  if (params.data && params.data.reviewer) {
                    return params.data.reviewer.firstName + ' ' + params.data.reviewer.lastName
                  } else {
                    return ''
                  }
                }
              },
              {
                field: "user",
                headerName: "EFT",
                cellRenderer: (params: any) => {
                  if (params.data && params.data.user) {
                    return params.data.user.firstName + ' ' + params.data.user.lastName
                  } else {
                    return ''
                  }
                }
              },
              {
                field: "status",
                headerName: "Status",
                sortable: true,
                filter: true
              }
            ]}>
          </InspectionsGrid>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default ProjectInspections
