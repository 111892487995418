import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Avatar, Box, Button, Dialog, DialogActions, DialogContent, FormControl, Grid, IconButton, Input, InputAdornment, InputLabel, List, ListItem, ListItemAvatar, ListItemText, TextField, Typography } from '@mui/material';
import GQLClient from '../GQLClient';
import { UpdateContactInput } from '../API';
import { ContactType } from '../models';
import { Search } from '@mui/icons-material';
import { searchContacts } from '../graphql/custom_queries'
import { clientContactsByClientId } from '../graphql/queries'

export const ContactPicker: React.FC<{
  open: boolean,
  hideLabel?: boolean,
  onSelect: Function,
  onClose: Function,
  projectClientId?: any,
  projectContacts?: any
}> = ({ open, hideLabel = false, onSelect, onClose, projectClientId, projectContacts }) => {
  const [contacts, setContacts] = useState<UpdateContactInput[]>([])
  const [selectedContact, setSelectedContact] = useState<UpdateContactInput>()
  const [query, setQuery] = useState<string>()
  const [label, setLabel] = useState<string>()

  const queryContacts = useCallback(async () => {
    try {
      let contactsData;
      if (query) {
        contactsData = await GQLClient.graphql({
          query: searchContacts, variables: {
            filter: {
              type: { eq: ContactType.CONTACT },
              or: [
                { firstName: { match: query } },
                { lastName: { match: query } },
                { companyName: { match: query } }
              ]
            }
          }
        }) as any
      } else {
        contactsData = await GQLClient.graphql({
          query: searchContacts, variables: {
            filter: {
              type: { eq: ContactType.CONTACT }
            }
          }
        }) as any
      }
      const contacts = contactsData.data.searchContacts.items.filter((c: any) => !c.deleted)
      // Behavior for picker in Project is different. Thus testing for projectClientId.
      if (!query && projectClientId) {
        // prioritize client contact so they appear at top of list
        const clientContacts = await GQLClient.graphql({ query: clientContactsByClientId, variables: { clientId: projectClientId } }) as any
        const fClientContacts = clientContacts.data.clientContactsByClientId.items.filter((c: any) => !c._deleted)
        const clientIds = fClientContacts.map((c: any) => c.contact.id)
        contacts.sort((a: any, b: any) => {
          if (clientIds.includes(a.id) > clientIds.includes(b.id)) {
            return -1;
          }
          if (clientIds.includes(a.id) < clientIds.includes(b.id)) {
            return 1;
          }
          return 0;
        })
        // Don't show contacts that are already in the project
        const projectContactsIds = projectContacts.map((c: any) => c.contact.id)
        const fContacts = contacts.filter((contact: any) => !projectContactsIds.includes(contact.id))
        setContacts(fContacts)
      } else {
        setContacts(contacts)
      }
    } catch (err) { console.log('error fetching contacts') }
  }, [query, projectClientId, projectContacts])


  useEffect(() => {
    if (open) {
      queryContacts()
    }
  }, [open, queryContacts])


  const close = () => {
    onClose && onClose()
    setLabel(undefined)
    setSelectedContact(undefined)
  }

  const select = (contact: UpdateContactInput) => {
    onSelect && onSelect(contact, label)
    setLabel(undefined)
    setSelectedContact(undefined)
  }

  function renderContact(item: UpdateContactInput, onSelect?: Function) {
    return (
      <ListItem key={item?.id} button onClick={() => onSelect && onSelect(item)} alignItems="flex-start" style={{ alignItems: 'center' }}>
        <ListItemAvatar>
          <Avatar>
            {`${item?.firstName ? item.firstName[0] : ''}${item?.lastName ? item.lastName[0] : ''}`}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          style={{ flex: 1 }}
          primary={`${item?.firstName} ${item?.lastName}`}
          secondary={item?.companyName}
        />
        <ListItemText
          style={{ flex: 1 }}
          primary={item?.email}
          secondary={item?.phone}
        />
      </ListItem>
    )
  }

  return (
    <Dialog fullWidth={true} maxWidth="sm" open={open} onClose={close}>
      <DialogContent>
        {!selectedContact &&
          <Box display="flex" flexDirection="column">
            <Grid container>
              <Grid container item xs={9} direction="row" alignItems="center">
                <FormControl fullWidth={true}>
                  <InputLabel
                    htmlFor="contact-search"
                    style={{ alignItems: "center" }}>
                    Search Contacts
                  </InputLabel>
                  <Input
                    id="contact-search"
                    type="text"
                    value={query}
                    fullWidth
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setQuery(event.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton onClick={queryContacts}
                        >
                          <Search />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
            <List
              component="div"
              dense={true}
            >
              {contacts?.map((item: any) => renderContact(item, () => {
                if (!hideLabel) {
                  setSelectedContact(item)
                } else {
                  select(item)
                }
              }))}
            </List>
          </Box>
        }
        {selectedContact &&
          <Box flexDirection="column" style={{ textAlign: 'center' }}>
            <Typography variant="h6" style={{ fontWeight: 'bold' }}>Add Contact</Typography>
            <TextField
              style={{ marginTop: 10, marginBottom: 10 }}
              label="Label"
              variant="outlined"
              size="small"
              fullWidth
              onChange={(event: ChangeEvent<HTMLInputElement>) => setLabel(event.target.value)}
              value={label || ''}
            />
            {renderContact(selectedContact)}
          </Box>
        }
      </DialogContent>
      <DialogActions>
        {selectedContact &&
          <>
            <Button
              onClick={() => {
                setLabel(undefined)
                setSelectedContact(undefined)
              }}
              color="primary"
              variant="outlined"
            >
              Back
            </Button>
            <Button onClick={() => select(selectedContact)} color="primary" variant="contained">
              Add
            </Button>
          </>
        }
        {!selectedContact &&
          <Button onClick={close} color="primary" variant="outlined">
            Cancel
          </Button>
        }
      </DialogActions>
    </Dialog>
  )
}