import React, { useCallback, useEffect, useState } from 'react';
import GQLClient from '../GQLClient';
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';

import { useParams } from 'react-router-dom';
import { getProject } from '../graphql/queries';
import { UpdateProjectInput } from '../API';
import { updateProject } from '../graphql/mutations';
import LoadingIndicator from '../common/LoadingIndicator';
import { LoadingButton } from '../common/LoadingButton';
import { get } from 'aws-amplify/api';

export default function ProjectReportSettings() {
  const { id } = useParams() as any

  const [project, setProject] = useState<UpdateProjectInput>()
  const [changes, setChanges] = useState<any>(undefined)

  const [reportTemplates, setReportTemplates] = useState<any>([])
  useEffect(() => {
    const fetchTemplates = async () => {

      const getTemplates = get({
        apiName: "jsreport",
        path: "/templates"
      })


      const response = await getTemplates.response;
      const templates = await response.body.json();

      setReportTemplates(templates)
    }

    fetchTemplates()
  }, [])

  const fetchProject = useCallback(async () => {
    const projectData = await GQLClient.graphql({ query: getProject, variables: { id: id } }) as any
    setProject(projectData.data.getProject)
  }, [id])

  const setProjectValue = (event: any) => {
    setProject({
      ...project,
      [event.target.name]: event.target.value
    } as UpdateProjectInput)

    setChanges({
      ...changes,
      [event.target.name]: event.target.value
    })
  }

  const saveChanges = async () => {
    const projectData = await GQLClient.graphql({
      query: updateProject, variables: {
        input: {
          id: project!.id,
          _version: project!._version,
          ...changes
        }
      }
    }) as any

    setProject(projectData.data.updateProject)
    setChanges(undefined)
  }

  useEffect(() => {
    fetchProject()
  }, [fetchProject])

  if (!project) {
    return <LoadingIndicator />
  }

  return (
    <Grid container spacing={3} alignItems="flex-start">
      <Grid container spacing={3} item xs={12}>
        <Grid item xs={8}>
          <FormControl fullWidth variant="outlined" size="small">
            <InputLabel id="report-template-label">Report Template</InputLabel>
            <Select
              name="reportTemplateId"
              labelId="report-template-label"
              label="Report Template"
              value={project.reportTemplateId || ''}
              onChange={setProjectValue}
            >
              {reportTemplates.map((t: any) => <MenuItem key={t.shortid} value={t.shortid}>{t.name}</MenuItem>)}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="reportDisclaimer"
            label="Disclaimer"
            fullWidth
            variant="outlined"
            multiline
            rows={20}
            maxRows={20}
            value={project.reportDisclaimer || ''}
            onChange={setProjectValue}
          />
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={saveChanges}
            disabled={!changes}
          >
            Save
          </LoadingButton>
        </Grid>
      </Grid>
    </Grid>
  )
}
