import React, { useEffect, useState } from 'react';
import { createClient } from '../graphql/mutations';
import { Button, Grid, TextField, Typography } from '@mui/material';
import GQLClient from '../GQLClient';

import { Navigate } from 'react-router-dom';
import { AddressEditor } from '../common/AddressEditor';
import { AddressInput, CreateClientInput } from '../API';
import { ClientStatus } from '../models';

const initialState = {
  name: '',
  status: ClientStatus.ACTIVE,
  code: '',
  address: {
    street: '',
    street2: '',
    city: '',
    state: '',
    zip: ''
  }
} as CreateClientInput

export default function NewClient() {
  const [redirectId, setRedirectId] = useState<string>('')
  const [client, setClient] = useState<CreateClientInput>(initialState)

  useEffect(() => {
  }, [])

  function setClientValue(key: string, value: string) {
    setClient({
      ...client,
      [key]: value
    })
  }

  function updateAddress(address: AddressInput) {
    setClient({
      ...client,
      address: address
    })
  }

  async function addClient() {
    try {
      if (!client.name) return
      const clientData = await GQLClient.graphql({ query: createClient, variables: { input: client } }) as any
      setRedirectId(clientData.data.createClient.id)
      setClient(initialState)
    } catch (err) {
      console.log('error creating client:', err)
    }
  }

  if (redirectId !== '') {
    return <Navigate to={`/clients/${redirectId}`} />
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5" style={{ fontWeight: 'bold' }}>
          Add Client
        </Typography>
      </Grid>
      <Grid container spacing={3} item xs={6}>
        <Grid item xs={12}>
          <TextField
            label="Client Name"
            variant="outlined"
            size="small"
            fullWidth
            onChange={(event: any) => setClientValue('name', event.target.value)}
            value={client.name}
            style={{ marginBottom: 20 }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Client Id"
            variant="outlined"
            size="small"
            fullWidth
            onChange={(event: any) => setClientValue('code', event.target.value)}
            value={client.code}
            style={{ marginBottom: 20 }}
          />
        </Grid>
        <Grid item xs={12}>
          <AddressEditor address={client.address} editing={true} onChange={updateAddress} />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          onClick={addClient}
          style={{ fontWeight: 'bold', textTransform: 'uppercase' }}
        >
          Add
        </Button>
      </Grid>
    </Grid>
  );
}
