import { Box, IconButton, ListItem, ListItemSecondaryAction, ListItemText, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { MoreHorizOutlined } from "@mui/icons-material";
import GQLClient from "../GQLClient";
import { bindMenu, bindTrigger } from "material-ui-popup-state";
import { usePopupState } from "material-ui-popup-state/hooks";
import { CreateExternalLinkInput, UpdateExternalLinkInput } from "../API";
import { updateExternalLink } from "../graphql/mutations";
import { ExternalLinkEditor } from "./ExternalLinkEditor";

export const ExternalLink: React.FC<{ item: UpdateExternalLinkInput | CreateExternalLinkInput, onSave: Function, onRemove: Function }> = ({ item, onSave, onRemove }) => {
    const popupState = usePopupState({ variant: 'popover', popupId: 'contactActionPopup' })
    const [editing, setEditing] = useState<boolean>(!item.id)

    async function _onSave(changes: any) {
        const linkData = await GQLClient.graphql({
            query: updateExternalLink, variables: {
                input: {
                    id: item.id,
                    _version: item._version,
                    ...changes
                }
            }
        }) as any
        const link = linkData.data.updateExternalLink

        setEditing(false)
        onSave(link)
    }

    function edit() {
        setEditing(true)
        popupState.close()
    }

    return (
        <ListItem ContainerComponent="div" alignItems="flex-start" style={{ alignItems: 'center' }}>
            <ListItemText style={{ flex: 1 }}>
                {!editing &&
                    <Box display="flex" flexDirection="column">
                        <a href={item.url!}>{item.title}</a>
                    </Box>
                }
                {editing &&
                    <ExternalLinkEditor item={item} onSave={_onSave} onCancel={() => setEditing(false)} />
                }
            </ListItemText>
            {!editing &&
                <ListItemSecondaryAction>
                    <IconButton edge="end" {...bindTrigger(popupState)}>
                        <MoreHorizOutlined />
                    </IconButton>
                    <Menu {...bindMenu(popupState)}>
                        <MenuItem onClick={edit}>Edit</MenuItem>
                        <MenuItem onClick={() => onRemove(item)}>Delete</MenuItem>
                    </Menu>
                </ListItemSecondaryAction>
            }
        </ListItem>
    )
}