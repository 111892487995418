import React from 'react';
import { Button, Grid, Paper, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import ProjectsGrid from './ProjectsGrid';
import { UpdateProjectInput } from '../API';

export const Projects = () => {
  const navigate = useNavigate();
  function onSelect(item: UpdateProjectInput) {
    navigate(`/projects/${item.id}`)
  }

  return (
    <Grid container spacing={3}>
      <Grid container item xs={12}>
        <Grid item xs={6}>
          <Typography variant="h5" style={{ fontWeight: 'bold' }}>
            Projects
          </Typography>
        </Grid>
        <Grid item xs={6} style={{ textAlign: 'right' }}>
          <Link to="/projects/new" style={{ textDecoration: 'none' }}>
            <Button variant="contained" color="primary">
              Add New
            </Button>
          </Link>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <ProjectsGrid
            id="projects"
            onSelect={onSelect}
            columnDefs={[
              {
                field: "code",
                sortable: true,
                filter: "agTextColumnFilter",
                filterParams: { suppressAndOrCondition: true }
              },
              {
                field: "clientId",
                headerName: "Client",
                sortable: true,
                filter: "clientFilter",
                cellRenderer: (params: any) => {
                  if (params.data && params.data.client) {
                    return params.data.client ? params.data.client.name : ''
                  } else {
                    return ''
                  }
                }
              },
              {
                field: "name",
                headerName: "Project",
                sortable: true,
                filter: "agTextColumnFilter",
                filterParams: { suppressAndOrCondition: true }
              },
              {
                field: "address.city",
                headerName: "City",
                sortable: false
              },
              {
                field: "address.state",
                headerName: "State",
                sortable: false
              },
              {
                headerName: "EFT",
                field: "eft",
                cellRenderer: (params: any) => {
                  if (params.data && params.data.eft) {
                    return params.data.eft.firstName + ' ' + params.data.eft.lastName
                  } else {
                    return ''
                  }
                }
              },
              {
                field: "status",
                sortable: true,
                filter: "agTextColumnFilter",
                filterParams: { suppressAndOrCondition: true }
              }
            ]}>
          </ProjectsGrid>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default Projects
