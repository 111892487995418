import React from 'react';
import GQLClient from '../GQLClient';
import { Button, Grid, Paper, Typography } from '@mui/material';
import { IDatasource, IGetRowsParams } from 'ag-grid-community';
import { Link, useNavigate } from 'react-router-dom';
import { AgGridToElasticsearch } from '../common/AgGridHelpers';
import { DataGrid } from '../common/DataGrid';
import { useCurrentUser } from '../hooks/AuthHooks';

export const searchUsers = /* GraphQL */ `
  query SearchUsers(
    $filter: SearchableUserFilterInput
    $sort: [SearchableUserSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        type
        firstName
        lastName
        companyName
        jobTitle
        phone
        email
        _version
        _deleted
        createdAt
        updatedAt
        owner
      }
      nextToken
      total
    }
  }
`;


export const Team = () => {
  const username = useCurrentUser();
  const navigate = useNavigate();
  function onRowSelected(item: any) {
    navigate(`/team/${item.id}`)
  }

  const gridDataSource: IDatasource = {
    rowCount: undefined, // behave as infinite scroll
    getRows: async function (params: IGetRowsParams) {
      if(!username) return;
      const searchVariables = AgGridToElasticsearch(params.sortModel, params.filterModel)

      const userData = await GQLClient.graphql({
        query: searchUsers, variables: {
          ...searchVariables,
          from: params.startRow,
        }
      }) as any
      const users = userData.data.searchUsers.items
      const total = userData.data.searchUsers.total || 0

      params.successCallback(users, total);
    },
  }

  return (
    <Grid container spacing={3}>
      <Grid container item xs={12}>
        <Grid item xs={6}>
          <Typography variant="h5" style={{ fontWeight: 'bold' }}>
            Team
          </Typography>
        </Grid>
        <Grid item xs={6} style={{ textAlign: 'right' }}>
          <Link to="/team/new" style={{ textDecoration: 'none' }}>
            <Button variant="contained" color="primary">
              Add New
            </Button>
          </Link>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <DataGrid
            id="team"
            datasource={gridDataSource}
            onSelect={onRowSelected}
            columnDefs={[
              {
                field: "firstName",
                headerName: "First Name",
                sortable: true,
                filter: "agTextColumnFilter",
                filterParams: { suppressAndOrCondition: true }
              },
              {
                field: "lastName",
                headerName: "Last Name",
                sortable: true,
                filter: "agTextColumnFilter",
                filterParams: { suppressAndOrCondition: true },
              },
              {
                field: "companyName",
                headerName: "Company Name",
                sortable: true,
                filter: "agTextColumnFilter",
                filterParams: { suppressAndOrCondition: true }
              },
              {
                field: "jobTitle",
                headerName: "Job Title",
                sortable: true,
                filter: "agTextColumnFilter",
                filterParams: { suppressAndOrCondition: true }
              },
              {
                field: "email",
                headerName: "Email",
                sortable: true,
                filter: "agTextColumnFilter",
                filterParams: { suppressAndOrCondition: true }
              },
              {
                field: "phone",
                headerName: "Phone Number",
                sortable: true,
                filter: "agTextColumnFilter",
                filterParams: { suppressAndOrCondition: true }
              },
              {
                field: "type",
                headerName: "Type",
                sortable: true,
                filter: "agTextColumnFilter",
                filterParams: { suppressAndOrCondition: true }
              }
            ]}>
          </DataGrid>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default Team
