import React, { useState } from 'react';
import { Theme, createStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Paper,
  TextField,
  Box,
} from '@mui/material';
import { UpdateContactInput } from '../API';
import GQLClient from '../GQLClient';
import { createContact } from '../graphql/mutations';
import { ContactType } from '../models';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 'auto',
    },
    paper: {
      width: 250,
      height: 230,
      overflow: 'auto',
      justifyContent: 'center',
    },
    button: {
      margin: theme.spacing(0.5, 0),
    },
  }),
);

function not(a: number[], b: number[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: number[], b: number[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

interface TransferListProps {
  updateDistributionList: Function
  items: [any]
  contacts: UpdateContactInput[]
  field: string
}

export default function TransferList({ updateDistributionList, items, contacts, field }: TransferListProps) {

  const itemIds = items.map(i => i.id)
  // const classes = useStyles();
  const [checked, setChecked] = React.useState<number[]>([]);
  const [left, setLeft] = React.useState<any[]>(contacts.filter((c: any) => !(itemIds.includes(c.id))));
  const [right, setRight] = React.useState<any[]>(items);
  const [externalContact, setExternalContact] = useState<any>({ firstName: '', lastName: '', email: '', jobTitle: '', phone: '', companyName: '' })
  const [addExternalContact, setAddExternalContact] = useState<boolean>(false)

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
    updateDistributionList(right.concat(left), field)
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
    updateDistributionList(right.concat(leftChecked), field)
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
    updateDistributionList(not(right, rightChecked), field)
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
    updateDistributionList([], field)
  };

  const newExternalContact = async (e: any) => {
    try {
      if (!externalContact.firstName) return
      await GQLClient.graphql({
        query: createContact, variables: {
          input: {
            ...externalContact,
            type: ContactType.CONTACT
          }
        }
      }) as any
      updateDistributionList(right.concat(externalContact), field)
      setRight(right.concat(externalContact))
      setAddExternalContact(false)
    } catch (err) {
      console.log('error creating contact:', err)
    }
  };

  const customList = (items: any[]) => (
    <Paper>
      <List dense component="div" role="list">
        {items.map((value: any) => {
          const labelId = `transfer-list-item-${value.email}-label`;

          return (
            <ListItem key={value.id ? value.id : value.email} role="listitem" onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value.firstName} ${value.lastName} ${value.email}`} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );


  return (
    <Grid container spacing={2} alignItems="center">
      {/* Left side list */}
      <Grid item xs={5} style={{ marginBottom: 20, paddingTop: 0 }}>
        <Box sx={{ height: 230, overflowY: 'auto', border: '1px solid #ccc', borderRadius: '4px' }}>
          {customList(left)}
        </Box>
      </Grid>

      {/* Control buttons */}
      <Grid item xs={2}>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            onClick={handleAllRight}
            disabled={left.length === 0}
            aria-label="move all right"
          >
            ≫
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={handleAllLeft}
            disabled={right.length === 0}
            aria-label="move all left"
          >
            ≪
          </Button>
        </Grid>
      </Grid>

      {/* Right side list */}
      <Grid item xs={5}>
        <Box sx={{ height: 230, overflowY: 'auto', border: '1px solid #ccc', borderRadius: '4px', padding: 0 }}>
          {customList(right)}
        </Box>
        <Box style={{ display: "flex", justifyContent: "center", paddingTop: 4 }}>
          <Button size="small" variant="contained" color="primary" onClick={() => setAddExternalContact(true)}>
            Add Contact
          </Button>
        </Box>
      </Grid>

      {/* Add External Contact Dialog */}
      <Dialog open={addExternalContact}>
        <DialogTitle id="form-dialog-title">Add External Contact</DialogTitle>
        <DialogContent>
          <TextField
            onChange={(e: any) => setExternalContact({ ...externalContact, firstName: e.target.value })}
            autoFocus
            margin="dense"
            id="firstName"
            label="First Name"
            type="text"
            fullWidth
          />
          <TextField
            onChange={(e: any) => setExternalContact({ ...externalContact, lastName: e.target.value })}
            margin="dense"
            id="lastName"
            label="Last Name"
            type="text"
            fullWidth
          />
          <TextField
            onChange={(e: any) => setExternalContact({ ...externalContact, companyName: e.target.value })}
            margin="dense"
            id="companyName"
            label="Company Name"
            type="text"
            fullWidth
          />
          <TextField
            onChange={(e: any) => setExternalContact({ ...externalContact, jobTitle: e.target.value })}
            margin="dense"
            id="jobTitle"
            label="Job Title"
            type="text"
            fullWidth
          />
          <TextField
            onChange={(e: any) => setExternalContact({ ...externalContact, email: e.target.value })}
            margin="dense"
            id="email"
            label="Email"
            type="email"
            fullWidth
          />
          <TextField
            onChange={(e: any) => setExternalContact({ ...externalContact, phone: e.target.value })}
            margin="dense"
            id="phone"
            label="Phone"
            type="text"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddExternalContact(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={(e) => newExternalContact(e)} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
