import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography
} from '@mui/material';
import { useNavigate } from "react-router-dom";
import { Edit, Delete } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
// import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import GQLClient from '../GQLClient';
import React, { ChangeEvent, useCallback, useContext, useEffect, useState } from 'react';
import {
  CreateCommentInput,
  CreateLocationInput,
  CreateObservationInput,
  FindingStatus,
  FindingType,
  InspectionPointType,
  ObservationType,
  UpdateFindingInput,
  UpdateObservationInput
} from '../API';
import { LoadingButton } from '../common/LoadingButton';
import LoadingIndicator from '../common/LoadingIndicator';
import { InspectionPointSelectionTree } from '../common/InspectionPointSelectionTree';
import {
  createFinding,
  deleteFinding,
  deleteObservation,
  updateFinding,
  updateInspectionPoint,
  createComment,
  createInspectionComment,
  createObservation,
  createLocation,
  updateObservation,
  updatePhoto
} from '../graphql/mutations';
import {
  findingsByProjectId,
  getFinding, tagsByProjectId
} from '../graphql/queries';
import {
  locationsByObservationId
} from '../graphql/custom_queries';
import { InspectionStatus, ObservationStatus } from '../models';
import { ObservationContext } from './ObservationContext';
import { ObservationLocation } from './ObservationLocation';
import { useCurrentUser } from '../hooks/AuthHooks';
import { Finding } from '../models';
import { red } from '@mui/material/colors';

type Props = {
  finding: UpdateFindingInput
};

const updateAnnotationColor = async (id: string, annotationColor: string) => {
  const locations = await GQLClient.graphql({ query: locationsByObservationId, variables: { observationId: id } }) as any
  locations.data.locationsByObservationId.items.forEach((element: any) => {
    element.photos.items.forEach((photo: any) => {
      const json = JSON.parse(photo.photo.json)
      if (json && json.objects) {
        json.objects.forEach((obj: any) => {
          obj.stroke = annotationColor
        });
        const newJson = JSON.stringify(json)
        GQLClient.graphql({ query: updatePhoto, variables: { input: { id: photo.photo.id, _version: photo.photo._version, json: newJson } } })
      }
    })
  });
}

export const ObservationDetail: React.FC<Props> = (props) => {
  const { inspection, editable, observation, update } = useContext(ObservationContext);

  const [inspectionPoint, setInspectionPoint] = useState<any>()
  const [primaryLocation, setPrimaryLocation] = useState<String>()
  const [primaryPhoto, setPrimaryPhoto] = useState<any | undefined>()
  const [locations, setLocations] = useState<any[]>([])
  const [projectTags, setProjectTags] = useState<any>()
  const [tagsChanged, setTagsChanged] = useState<boolean>(false)
  const [reordering, setReordering] = useState<boolean>(false)
  const [cancelled, setCancelled] = useState<boolean>(false)
  const [warnDate, setWarnDate] = useState<boolean>(false)
  const [locationEditing, setLocationEditing] = useState<boolean>(false)

  const user = useCurrentUser()
  let navigate = useNavigate()


  useEffect(() => {
    if (observation) {
      const rawObservation = observation as any
      setInspectionPoint(rawObservation.inspectionPoint)
      const locs = rawObservation.locations.items?.filter((l: any) => !l._deleted) as any[]
      if (rawObservation.primaryLocation) {
        locs.sort((a: any, b: any) => {
          if ((rawObservation?.primaryLocation.id === a.id) > (rawObservation?.primaryLocation.id === b.id)) return -1
          else if ((rawObservation?.primaryLocation.id === a.id) < (rawObservation?.primaryLocation.id === b.id)) return 1;
          else return 0;
        })
      }
      setLocations(locs)
      setPrimaryLocation(rawObservation.primaryLocation && rawObservation.primaryLocation.id)
      setPrimaryPhoto(rawObservation.primaryLocation.primaryPhoto ? rawObservation.primaryLocation.primaryPhoto : undefined)
    } else {
      setInspectionPoint(undefined)
      setLocations([])
    }
  }, [observation, reordering])

  const [finding, setFinding] = useState<UpdateFindingInput>()
  const [rawFinding, setRawFinding] = useState<Finding>()
  const fetchFinding = useCallback(async () => {
    if (observation?.findingId) {
      const findingData = await GQLClient.graphql({ query: getFinding, variables: { id: observation.findingId } }) as any
      const finding = findingData.data.getFinding
      setFinding(finding)
      setRawFinding(finding)
    } else {
      setFinding(undefined)
      setRawFinding(undefined)
    }
  }, [observation])

  useEffect(() => {
    fetchFinding()
  }, [fetchFinding])

  const updateInspectionComments = async (comment: string) => {
    // Update inspection comments.
    let commentInput: CreateCommentInput = {
      groupId: inspection!.groupId || inspection!.projectId,
      userId: user!,
      date: new Date().toISOString(),
      text: comment,
      projectId: inspection!.projectId
    }

    const commentData = await GQLClient.graphql({ query: createComment, variables: { input: commentInput } }) as any
    const commentResponse = commentData.data.createComment

    await GQLClient.graphql({
      query: createInspectionComment, variables: {
        input: {
          groupId: inspection!.groupId || inspection!.projectId,
          inspectionId: inspection!.id,
          commentId: commentResponse.id,
          projectId: inspection!.projectId
        }
      }
    }) as any
  }

  const fetchProjectTags = useCallback(async () => {
    const projectData = await GQLClient.graphql({ query: tagsByProjectId, variables: { projectId: inspection?.projectId } }) as any
    const projectTags = projectData.data.tagsByProjectId.items
    setProjectTags(projectTags)
  }, [inspection?.projectId])

  useEffect(() => {
    fetchProjectTags()
  }, [fetchProjectTags])

  const changeTags = () => {
    // Rerender locations, tag verbiage might have changed.
    setTagsChanged(!tagsChanged)
  }


  const [annotatorColor, setAnnotatorColor] = useState<string>('green')
  useEffect(() => {
    if (finding?.type === FindingType.NCI && observation?.type === ObservationType.FINDING) {
      setAnnotatorColor('red')
    } else if (finding?.type === FindingType.IOC && observation?.type === ObservationType.FINDING) {
      setAnnotatorColor('yellow')
    } else {
      setAnnotatorColor('green')
    }
  }, [observation, finding])

  const [displayValues, setDisplayValues] = useState<any>({
    type: '',
    text: '',
    suggestion: ''
  })
  const resetValues = useCallback(() => {
    setDisplayValues({
      type: finding?.type || observation?.type || '',
      text: observation?.text || '',
      suggestion: observation?.suggestion || ''
    })
    setFindingType(finding?.type || undefined)
  }, [observation, finding])

  useEffect(() => {
    resetValues()
  }, [resetValues])

  const [saving, setSaving] = useState<boolean>(false)
  const [locationSaving, setLocationSaving] = useState<boolean>(false)
  const [editing, setEditing] = useState<boolean>(false)
  const [changes, setChanges] = useState<any>()
  const [locationChanges, setLocationChanges] = useState<any>()
  const [findingType, setFindingType] = useState<FindingType>()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setChanges({
      ...changes,
      [event.target.name]: event.target.value
    })

    setDisplayValues({
      ...displayValues,
      [event.target.name]: event.target.value
    })
  }
  const [warnDialogOpen, setWarnDialogOpen] = useState<boolean>(false)

  const warn = (warning: string) => {
    if (finding && finding?.inspectionId !== inspection?.id) {
      setWarnDialogOpen(true)
      updateInspectionComments(`${warning} - ${finding?.type} ${finding?.code}`)
    }
  }

  const handleTypeChange = (type: ObservationType, findingType?: FindingType) => {
    warn(`Type changed on observation ${observation?.id}`)
    setChanges({
      ...changes,
      type: type
    })

    setFindingType(findingType)

    setDisplayValues({
      ...displayValues,
      type: type
    })
  }

  const cancelEditing = () => {
    setChanges(undefined)
    resetValues()
    setEditing(false)
  }

  const cancelLocationEditing = () => {
    setReordering(false)
    setLocationEditing(false)
    setCancelled(!cancelled)
  }

  const save = async () => {
    if (!observation) return
    warn(`Changes made to observation ${observation?.id + ' ' + JSON.stringify(changes)}`)
    setSaving(true)

    if (changes.type) {
      if (changes.type === ObservationType.FINDING) {
        const annotationColor = findingType === FindingType.NCI ? 'red' : 'yellow'
        if (finding) {
          if (findingType !== finding.type) {
            const findingData = await GQLClient.graphql({
              query: updateFinding, variables: {
                input: {
                  id: finding.id,
                  _version: finding._version,
                  type: findingType
                }
              }
            }) as any
            const updatedFinding = findingData.data.updateFinding
            setFinding(updatedFinding)
            updateAnnotationColor(observation.id, annotationColor)
          }
        }
        else {
          // Determine value for finding code.
          const findingsData = await GQLClient.graphql({
            query: findingsByProjectId, variables: {
              projectId: inspection?.projectId
            }
          }) as any
          let rawInspection = inspection as any
          const codeNumber = Math.max(...findingsData.data.findingsByProjectId.items.map((f: any) => f.code ? parseInt(f.code.split('-').slice(-1)) : null))
          const code = codeNumber ? `${rawInspection!.project.code}-${codeNumber + 1}` : `${rawInspection!.project.code}-1`

          const findingData = await GQLClient.graphql({
            query: createFinding, variables: {
              input: {
                groupId: observation.groupId || observation.projectId,
                code: code,
                inspectionId: observation.inspectionId,
                projectId: inspection!.projectId,
                hierarchyItemId: observation.hierarchyItemId,
                observationId: observation.id,
                phaseId: inspection?.phaseId,
                type: findingType,
                status: FindingStatus.NEW,
                date: inspection?.inspectionDate ? new Date(inspection.inspectionDate).toISOString() : undefined
              }
            }
          }) as any
          const newFinding = findingData.data.createFinding
          // Update observation
          await GQLClient.graphql({
            query: updateObservation, variables: {
              input: {
                id: observation.id,
                _version: observation._version,
                type: ObservationType.FINDING,
                findingId: newFinding.id
              }
            }
          })
          updateAnnotationColor(observation.id, annotationColor)
        }
      } else if (finding) {
        await GQLClient.graphql({ query: deleteFinding, variables: { input: { id: finding.id, _version: finding._version } } })
        // Update observation
        await GQLClient.graphql({
          query: updateObservation, variables: {
            input: {
              id: observation.id,
              _version: observation._version,
              type: ObservationType.CONFORMING,
              findingId: null
            }
          }
        })
        updateAnnotationColor(observation.id, 'green')
      }
    }

    await update(changes)
    setSaving(false)
    setChanges(undefined)
    setEditing(false)
  }

  const saveLocation = async () => {
    setLocationSaving(false)
    setLocationChanges(undefined)
    setLocationEditing(false)
  }

  const markAccepted = async () => {
    if (!observation) return

    update({
      status: ObservationStatus.ACCEPTED
    })
  }

  const markDeclined = async () => {
    if (!observation) return

    update({
      status: ObservationStatus.DECLINED
    })
  }

  const [inspectionPointText, setInspectionPointText] = useState<string>('')
  useEffect(() => {
    setInspectionPointText(inspectionPoint?.text ? inspectionPoint.text : '')
  }, [inspectionPoint])

  const [editingInspectionPoint, setEditingInspectionPoint] = useState<boolean>(false)

  const saveInspectionPoint = async () => {
    if (!inspectionPoint) return

    warn(`Inspection Point text updated on observation ${observation?.id}`)

    const inspectionPointData = await GQLClient.graphql({
      query: updateInspectionPoint, variables: {
        input: {
          id: inspectionPoint.id,
          _version: inspectionPoint._version,
          text: inspectionPointText
        }
      }
    }) as any

    const updatedInspectionPoint = inspectionPointData.data.updateInspectionPoint
    setInspectionPoint(updatedInspectionPoint)
    setEditingInspectionPoint(false)
  }

  const cancelEditingInspectionPoint = () => {
    if (!inspectionPoint) return
    setInspectionPointText(inspectionPoint.text ? inspectionPoint.text : '')
    setEditingInspectionPoint(false)
  }

  const changePrimaryLocation = async (loc: String) => {
    warn(`Primary location changed on observation ${observation?.id}`)
    setPrimaryLocation(loc)
    update({ observationPrimaryLocationId: loc })
  }

  const doneUploading: Function = () => {
    setLocationEditing(false)
    setCancelled(!cancelled)
  }

  const updateReordering: Function = (val: boolean) => {
    setReordering(val)
  }

  const handleClosingDateChange = async (date: Date | null) => {
    try {
      let input: UpdateFindingInput = {
        id: finding!.id,
        _version: finding!._version,
        closedDate: date?.toISOString()
      }
      const findingData = await GQLClient.graphql({ query: updateFinding, variables: { input: input } }) as any
      const updatedFinding = findingData.data.updateFinding
      setFinding(updatedFinding)
      return
    }
    catch {
      return
    }
  }

  const revertCloseDate = () => {
    setWarnDate(true)
    return
  }

  const handleClose = (action: String) => {
    if (action === "ok") {
      handleClosingDateChange(new Date(inspection!.inspectionDate!))
    }
    setWarnDate(false)
    return
  }

  const [openDialog, setOpenDialog] = useState<any>({
    show: false,
    message: '',
    okDisabled: true
  })

  const handleDeleteObservation = () => {
    if (!observation) {
      return
    }

    if (
      observation.type === ObservationType.FINDING &&
      finding &&
      finding.status === FindingStatus.CLOSED
    ) {
      setOpenDialog({
        show: true, message: `This finding was closed on ${finding.closedDate?.split('T')[0]} and can't be deleted directly. 
      Please delete closing observation before deleting this observation.`, okDisabled: true
      })
      return
    } else {
      setOpenDialog({
        show: true, message: `Are you sure you want to delete this observation? 
      </br></br> If this report has been previously distributed to the client, make required project notation 
      and notify client of revisions as needed.`, okDisabled: false
      })
      return
    }
  }

  const removeObservation = async () => {
    if (finding) {
      if (observation!.type === ObservationType.FINDING) {
        await GQLClient.graphql({ query: deleteFinding, variables: { input: { id: finding.id, _version: finding._version } } })
      } else if (observation!.type === ObservationType.RESOLUTION) {
        // Reopen finding
        const results = await GQLClient.graphql({ query: getFinding, variables: { id: observation!.findingId } }) as any
        const findingToUpdate = results.data.getFinding
        if (findingToUpdate) {
          const item = {
            id: findingToUpdate.id,
            _version: findingToUpdate._version,
            findingClosingObservationId: null,
            closedDate: null,
            status: finding.inspectionId === observation?.inspectionId
              ? FindingStatus.NEW
              : FindingStatus.OPEN
          }
          await GQLClient.graphql({ query: updateFinding, variables: { input: item } })
        }
      }

    }
    await GQLClient.graphql({ query: deleteObservation, variables: { input: { id: observation?.id, _version: observation?._version } } })
    updateInspectionComments(`Observation deleted ${observation!.text ? '"' + observation!.text + '"' : ''}, id: ${observation!.id}`)
    setOpenDialog({
      show: false,
      message: '',
      okDisabled: true
    })
    navigate(-1)
  }



  const closeObservation = async () => {

    if (!finding) {
      return
    }

    const observationData = await GQLClient.graphql({
      query: createObservation, variables: {
        input: {
          projectId: inspection?.projectId,
          groupId: finding!.groupId || finding!.projectId,
          inspectionId: inspection?.id,
          hierarchyItemId: finding!.hierarchyItemId,
          inspectionPointId: observation?.inspectionPointId,
          findingId: finding!.id,
          status: ObservationStatus.NEW,
          type: ObservationType.RESOLUTION
        } as CreateObservationInput
      }
    }) as any

    const newObservation = observationData.data.createObservation

    const primaryLocationData = await GQLClient.graphql({
      query: createLocation, variables: {
        input: {
          groupId: finding!.groupId || finding!.projectId,
          observationId: newObservation.id,
          projectId: inspection?.projectId,
        } as CreateLocationInput
      }
    }) as any

    const primaryLocation = primaryLocationData.data.createLocation

    await GQLClient.graphql({
      query: updateObservation, variables: {
        input: {
          id: newObservation.id,
          _version: newObservation._version,
          observationPrimaryLocationId: primaryLocation.id
        } as UpdateObservationInput
      }
    })

    await GQLClient.graphql({
      query: updateFinding, variables: {
        input: {
          id: finding.id,
          _version: finding._version,
          status: FindingStatus.CLOSED,
          findingClosingObservationId: newObservation.id,
          // set closing date to the actual day it was closed.
          // differs from mobile where closed date is set to inspection
          // date.
          closedDate: new Date(new Date().setUTCHours(12, 0, 0, 0)).toISOString()
        } as UpdateFindingInput
      }
    }) as any

    navigate(`/inspection/${inspection!.id}/observations/${newObservation?.id}`)
  }

  const [movingInspectionPoint, setMovingInspectionPoint] = useState<boolean>(false)
  const moveInspectionPoint = () => {
    setMovingInspectionPoint(true)
  }

  const [newInspectionPoint, setNewInspectionPoint] = useState<any>()
  const handleMoveInspectionPoint = async (e: any) => {
    warn(`Inspection Point moved on observation ${observation?.id}`)
    const updateInput: UpdateObservationInput = {
      id: observation!.id,
      _version: observation!._version,
      hierarchyItemId: newInspectionPoint.hierarchyItemId,
      inspectionPointId: newInspectionPoint.id
    }
    update({ ...updateInput })
    // If this is part of a closed finding move inspection point for 
    // other observation.
    let observationToChange: any = null
    if (observation!.type === ObservationType.RESOLUTION) {
      observationToChange = rawFinding?.observation
    } else if (observation!.type === ObservationType.FINDING && rawFinding?.closingObservation) {
      observationToChange = rawFinding?.closingObservation
    }
    if (observationToChange) {
      const updateInput: UpdateObservationInput = {
        id: observationToChange!.id,
        _version: observationToChange!._version,
        hierarchyItemId: newInspectionPoint.hierarchyItemId,
        inspectionPointId: newInspectionPoint.id
      }
      update({ ...updateInput })
    }
    // TODO Maybe? delete original inspection point if not used on another observation.
    setMovingInspectionPoint(false)
    setEditingInspectionPoint(false)
  }

  if (!observation || !inspectionPoint) {
    return <LoadingIndicator />
  }

  return (
    <Grid container spacing={3}>
      {finding?.status === FindingStatus.CLOSED &&
        observation.type === ObservationType.RESOLUTION &&
        observation.inspectionId === inspection?.id ?
        <Grid item xs={12}>
          {finding.closedDate === inspection?.inspectionDate ?
            <>
              <Button onClick={() => setIsOpen(true)} disabled={!editable} variant="contained"> Override Date </Button>
              <DatePicker
                label="Closed Date"
                open={isOpen}
                onOpen={() => setIsOpen(true)}
                onClose={() => setIsOpen(false)}
                value={finding!.closedDate}
                onChange={date => handleClosingDateChange(date as Date | null)}
                readOnly={!editable}
                slotProps={{
                  textField: {
                    sx: { display: 'none' }, // Hides the TextField component
                  }
                }}
                disableOpenPicker={!editable}
                sx={{ width: '100%' }}
              />
            </>
            :
            <Box>
              <Typography variant="button" style={{ fontWeight: 'bold' }}>
                Correction Date Overridden to: {new Date(finding?.closedDate!).toDateString()}
                {editable &&
                  <IconButton style={{ margin: 0 }} size="small" onClick={() => revertCloseDate()} disabled={!editable}>
                    <Delete />
                  </IconButton>
                }
              </Typography>
            </Box>
          }
        </Grid> : null
      }
      <Dialog open={warnDate}>
        <DialogTitle id="alert-dialog-title">{"Revert Closing Date?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This will change the finding closing date to the inspection date.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose("cancel")} id="cancel" color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleClose("ok")} color="primary" id="ok" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Grid item xs={12}>
        <Card variant="outlined" style={{ width: '100%' }}>
          <CardHeader style={{ backgroundColor: 'silver' }}
            title='Inspection Point'
            titleTypographyProps={{ style: { fontWeight: 'bold', textTransform: 'uppercase', fontSize: 14 } }}
            action={
              <div>
                {editable && !editingInspectionPoint &&
                  <IconButton style={{ margin: 0 }} size="small" onClick={() => setEditingInspectionPoint(true)}>
                    <Edit />
                  </IconButton>
                }
              </div>
            }
          />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {!editingInspectionPoint &&
                  <Typography variant="h5">
                    {inspectionPointText}
                  </Typography>
                }
                {editingInspectionPoint &&
                  <TextField
                    label="Inspection Point"
                    disabled={inspectionPoint.type === InspectionPointType.APPROVED}
                    fullWidth
                    variant="outlined"
                    multiline
                    rows={4}
                    maxRows={4}
                    value={inspectionPointText}
                    onChange={(event) => setInspectionPointText(event.target.value)}
                    style={{ backgroundColor: '#ffffff' }}
                  />
                }
              </Grid>
              {editingInspectionPoint &&
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    style={{ marginRight: 10 }}
                    color="primary"
                    onClick={() => cancelEditingInspectionPoint()}
                  >
                    Cancel
                  </Button>

                  <LoadingButton
                    variant="contained"
                    style={{ marginRight: 10 }}
                    color="primary"
                    disabled={inspectionPointText === inspectionPoint.text}
                    onClick={() => saveInspectionPoint()}
                  >
                    Save
                  </LoadingButton>
                  <Button
                    variant="outlined"
                    style={{ marginRight: 10 }}
                    color="primary"
                    onClick={() => moveInspectionPoint()}
                  >
                    Move to New CSI Location
                  </Button>
                </Grid>
              }
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card variant="outlined" style={{ width: '100%' }}>
          <CardHeader style={{ backgroundColor: 'silver' }}
            title={
              <>
                {observation.type === ObservationType.RESOLUTION && finding?.type === FindingType.NCI &&
                  'Correction'
                }
                {observation.type === ObservationType.RESOLUTION && finding?.type === FindingType.IOC &&
                  'Resolution'
                }
                {observation.type !== ObservationType.RESOLUTION &&
                  'Observation'
                }
              </>
            }
            titleTypographyProps={{ style: { fontWeight: 'bold', textTransform: 'uppercase', fontSize: 14 } }}
            action={
              <div>
                {editable &&
                  !editing &&
                  !(observation.type === ObservationType.RESOLUTION) &&
                  //!(finding ? finding.status === FindingStatus.CLOSED : false) &&
                  <IconButton style={{ margin: 0 }} size="small" onClick={() => setEditing(true)}>
                    <Edit />
                  </IconButton>
                }
              </div>
            }
          />
          <CardContent>
            <Grid container spacing={3}>
              {observation.type !== ObservationType.RESOLUTION &&
                <Grid item xs={12}>
                  <Button
                    variant={findingType === FindingType.NCI ? 'contained' : 'outlined'}
                    style={{ marginRight: 10 }}
                    color="primary"
                    disabled={saving || !editing}
                    onClick={() => handleTypeChange(ObservationType.FINDING, FindingType.NCI)}
                  >
                    Non-Conforming Item
                  </Button>
                  <Button
                    variant={findingType === FindingType.IOC ? 'contained' : 'outlined'}
                    style={{ marginRight: 10 }}
                    color="primary"
                    disabled={saving || !editing}
                    onClick={() => handleTypeChange(ObservationType.FINDING, FindingType.IOC)}
                  >
                    Item of Concern
                  </Button>
                  <Button
                    variant={displayValues.type === ObservationType.CONFORMING ? 'contained' : 'outlined'}
                    color="primary"
                    disabled={saving || !editing || (finding ? finding.status === FindingStatus.CLOSED : false)}
                    onClick={() => handleTypeChange(ObservationType.CONFORMING)}
                  >
                    Conforming Item
                  </Button>
                </Grid>
              }
              {observation.type !== ObservationType.RESOLUTION &&
                <Grid item xs={12}>
                  <TextField
                    label='Observation'
                    fullWidth
                    variant="outlined"
                    multiline
                    rows={4}
                    maxRows={4}
                    value={displayValues.text}
                    name="text"
                    onChange={handleChange}
                    InputProps={{
                      readOnly: saving || !editing,
                    }}
                    style={{ backgroundColor: '#ffffff' }}
                  />
                </Grid>
              }
              {observation.type === ObservationType.RESOLUTION &&
                <Typography style={{ paddingLeft: 15, paddingTop: 5 }}>
                  {primaryPhoto ? primaryPhoto.text : ''}
                </Typography>
              }
              {observation.type !== ObservationType.RESOLUTION && finding &&
                <Grid item xs={12}>
                  <TextField
                    label={finding.type === FindingType.NCI ? 'Suggested Correction' : 'Suggested Resolution'}
                    fullWidth
                    variant="outlined"
                    multiline
                    rows={4}
                    maxRows={4}
                    value={displayValues.suggestion}
                    name="suggestion"
                    onChange={handleChange}
                    InputProps={{
                      readOnly: saving || !editing,
                    }}
                    style={{ backgroundColor: '#ffffff' }}
                  />
                </Grid>
              }
              {editing &&
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    style={{ marginRight: 10 }}
                    color="primary"
                    disabled={saving}
                    onClick={() => cancelEditing()}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    variant="contained"
                    style={{ marginRight: 10 }}
                    color="primary"
                    disabled={!changes || saving}
                    onClick={() => save()}
                  >
                    Save
                  </LoadingButton>
                </Grid>
              }
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card variant="outlined" style={{ width: '100%' }}>
          <CardHeader style={{ backgroundColor: 'silver' }}
            title='Locations'
            titleTypographyProps={{ style: { fontWeight: 'bold', textTransform: 'uppercase', fontSize: 14 } }}
            action={
              <div>
                {editable && !locationEditing &&
                  <IconButton style={{ margin: 0 }} size="small" onClick={() => setLocationEditing(true)}>
                    <Edit />
                  </IconButton>
                }
              </div>
            }
          />
          <CardContent>
            {locations.length && locations.map(location =>
              <Grid key={location.id} item xs={12}>
                <Box mb={2}>
                  <ObservationLocation
                    id={location.id}
                    annotatorColor={annotatorColor}
                    editable={editable}
                    inspectionId={inspection!.id}
                    clientId={inspection!.clientId!}
                    canUpload={locationEditing}
                    doneUploading={doneUploading}
                    changePrimaryLocation={changePrimaryLocation}
                    primaryLocation={location.id === primaryLocation}
                    projectTags={projectTags}
                    fetchProjectTags={fetchProjectTags}
                    tagsChanged={tagsChanged}
                    changeTags={changeTags}
                    updateReordering={updateReordering}
                    reordering={reordering}
                    cancelled={cancelled}
                    warn={warn}
                  />
                </Box>
              </Grid>
            )}
            {locationEditing &&
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  style={{ marginRight: 10, marginTop: 10 }}
                  color="primary"
                  disabled={locationSaving}
                  onClick={() => cancelLocationEditing()}
                >
                  Cancel
                </Button>
                {locationChanges &&
                  <LoadingButton
                    variant="contained"
                    style={{ marginRight: 10, marginTop: 10 }}
                    color="primary"
                    disabled={!locationChanges || locationSaving}
                    onClick={() => saveLocation()}
                  >
                    Save
                  </LoadingButton>
                }
              </Grid>
            }
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        {editable && !editing &&
          <>
            <LoadingButton
              variant={observation.status === ObservationStatus.DECLINED ? "contained" : "outlined"}
              style={{ marginRight: 10 }}
              color="secondary"
              onClick={markDeclined}
              disabled={!editable || inspection!.status === InspectionStatus.ACTIVEW}
            >
              {observation.status === ObservationStatus.DECLINED ? "Declined" : "Decline"}
            </LoadingButton>
            <LoadingButton
              variant={observation.status === ObservationStatus.ACCEPTED ? "contained" : "outlined"}
              style={{ marginRight: 10 }}
              color="primary"
              onClick={markAccepted}
              disabled={!editable || inspection!.status === InspectionStatus.ACTIVEW}
            >
              {observation.status === ObservationStatus.ACCEPTED ? "Accepted" : "Accept"}
            </LoadingButton>
          </>
        }
        <Button
          onClick={handleDeleteObservation}
          style={{ marginRight: 10 }}
          disabled={!(inspection!.status === InspectionStatus.REVIEWING || inspection!.status === InspectionStatus.REVISING)}
          variant="contained"
          color="secondary"
        >
          Delete {observation?.type === ObservationType.CONFORMING ? 'Observation'
            : observation?.type === ObservationType.FINDING ? 'Observation'
              : finding?.type === FindingType.IOC ? 'Resolution' : 'Correction'}
        </Button>
        {(finding?.status === FindingStatus.OPEN || finding?.status === FindingStatus.NEW) &&
          <Button
            onClick={closeObservation}
            disabled={!(inspection!.status === InspectionStatus.REVIEWING || inspection!.status === InspectionStatus.REVISING)}
            variant="contained"
            color="primary"
          >
            Close Observation
          </Button>
        }

      </Grid>
      <Dialog open={openDialog.show}>
        <DialogTitle id="alert-dialog-title">Delete Observation?</DialogTitle>
        <DialogContent>
          <DialogContent>
            <div dangerouslySetInnerHTML={{ __html: openDialog.message }} />
          </DialogContent>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog({ show: false })} id="cancel" color="primary">
            Cancel
          </Button>
          {!openDialog.okDisabled &&
            <Button onClick={() => removeObservation()} color="primary" id="ok" autoFocus>
              OK
            </Button>
          }
        </DialogActions>
      </Dialog>
      <Dialog open={warnDialogOpen}>
        <DialogTitle id="alert-dialog-title">Edit Warning</DialogTitle>
        <DialogContent>
          <DialogContent>
            <Typography>CAUTION!! You are editing an observation from a PREVIOUS inspection. This may change an inspection that has already been sent. A reference to the change will be made in the comments of this inspection. DO NOT change the observation TYPE without consulting the Chief Engineer. </Typography>
          </DialogContent>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setWarnDialogOpen(false)} color="primary" id="ok" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={movingInspectionPoint}>
        <DialogContent>
          <Typography variant='h5'>Move Inspection Point</Typography>
          <InspectionPointSelectionTree
            setNewInspectionPoint={setNewInspectionPoint}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setMovingInspectionPoint(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={(e) => handleMoveInspectionPoint(e)} color="primary">
            Move
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
